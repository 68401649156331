.service-333 {
  width: 50%;
  text-wrap: wrap;
  inline-size: 380px;
  margin-right: 250px;
}

.online-22 {
  text-align: center;
}

.innerServices59 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 100px;
}

.innerServices59content {
  color: #000000;
  padding: 0 50px;
  height: 200px;
  text-align: center;
  font-size: 13px;
}
select#vin {
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23727272" viewBox="0 0 20 20"><path d="M5 8l6 6 6-6z"/></svg>')
    right 1rem center no-repeat;
}

select#domain {
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23727272" viewBox="0 0 20 20"><path d="M5 8l6 6 6-6z"/></svg>')
    right 1rem center no-repeat;
}
select.inputField {
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23727272" viewBox="0 0 20 20"><path d="M5 8l6 6 6-6z"/></svg>')
    right 1rem center no-repeat;
}
select.inputFields {
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23727272" viewBox="0 0 20 20"><path d="M5 8l6 6 6-6z"/></svg>')
    right 1rem center no-repeat;
}
select#words_pages {
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23727272" viewBox="0 0 20 20"><path d="M5 8l6 6 6-6z"/></svg>')
    right 1rem center no-repeat;
}
select#tool {
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23727272" viewBox="0 0 20 20"><path d="M5 8l6 6 6-6z"/></svg>')
    right 1rem center no-repeat;
}
.title99 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.service-133,
.service-233,
.service-3333 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 33.33%;
  justify-content: space-between;
  text-align: justify;
}

@media (max-width: 1140px) {
  .innerServices59 {
    flex-direction: column;
  }
  .service-133,
  .service-233,
  .service-3333 {
    width: 100%;
    gap: 20px;
  }
  .innerServices59content {
    height: 100px;
  }

  .companiessss img {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    display: flex;
    margin-top: 0px !important;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1140px) {
    .innerServices59 {
      flex-direction: column;
    }
    .service-133,
    .service-233,
    .service-3333 {
      width: 100%;
      gap: 20px;
    }
    .innerServices59content {
      height: 100px;
    }

    .companiessss img {
      background-repeat: no-repeat;
      background-size: cover;
      width: 100% !important;
      display: flex;
      margin-top: 0px !important;
      justify-content: center;
      align-items: center;
    }
  }
}
@media (max-width: 560px) {
  /* .innerServices59{
            flex-direction: column;
        }
        .service-133 ,.service-233,.service-3333{
            width: 100%;
            gap: 20px;
        }
        .innerServices59content{
            height: 100px;
        } */

  .companiessss img {
    /* background-repeat: no-repeat;
            background-size: cover;
            width: 100% !important; */
    /* display: flex; */
    /* margin-top: 50px !important; */
    /* justify-content: center; */
    /* align-items: center; */
  }
}
@media (max-width: 552px) {
  .service-133,
  .service-233 {
    /* margin-bottom: 50px; */
  }
}
@media (max-width: 460px) {
  .service-133,
  .service-233 {
    /* margin-bottom: 80px; */
  }
}

@media (max-width: 940px) {
  .innerServices59contenth22 {
    margin-top: 10px;
  }
}
@media (max-width: 400px) {
  .service-133,
  .service-233 {
    /* margin-bottom: 130px; */
  }
}
@media (max-width: 330px) {
  .service-133,
  .service-233 {
    margin-bottom: 50px !important;
  }
  .service-3333 {
    margin-bottom: 50px !important;
  }
}
@media (max-width: 866px) {
  .innerServices59contenth22 {
    margin-top: 50px !important;
  }
}
@media (max-width: 921px) {
  .innerServices59contenth23 {
    margin-top: 50px;
  }
}

@media (max-width: 852px) {
  .innerServices59contenth23 {
    margin-top: 50px;
  }
}

@media (max-width: 552px) {
  .innerServices59content {
    margin-bottom: 0px;
  }
}

@media (max-width: 926px) {
  .innerServices59contenth22 {
    margin-top: 50px;
  }

  .digit {
    color: #5c36f9;
    font-size: 40px !important;
    font-family: sans-serif;
    margin-top: 30px;
  }
  .digit2 {
    margin-left: 20px;
  }
}

@media (max-width: 435px) {
  .digit2 {
    margin-left: 0px !important;
  }
}
@media (max-width: 650px) {
  .expart-team1 {
    font-size: 12px !important;
  }

  .about-us56 {
    font-size: 20px !important;
  }
  .title99 {
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .formBox2 {
    width: 360px !important;
  }

  .buttonBox {
    width: 310px !important;
    position: relative;
    top: 20px !important;
    flex-wrap: wrap;
    margin-bottom: 130px;
  }

  #btn {
    height: 50% !important;
  }
  .inputFieldsssss {
    width: 400px s !important;
  }
  .inputField {
    width: 270px !important;
  }
  .inputFields {
    width: 270px !important;
  }
  .inputGroupp {
    top: 70px !important;
    margin-top: 30px !important;
  }
  .inputFieldsss {
    width: 70px !important;
  }

  .startBtn {
    width: 70% !important;
    position: relative;
    left: -50px !important;
  }
}

@media (max-width: 380px) {
  .formBox2 {
    width: 250px !important;
  }

  .buttonBox {
    width: 220px !important;
    position: relative;
    top: 20px !important;
    flex-wrap: wrap;
    margin-bottom: 130px;
  }

  #btn {
    height: 50% !important;
  }

  .inputField {
    width: 150px !important;
  }
  .inputFields {
    width: 15px !important;
  }
  .inputGroupp {
    width: 220px !important;
  }
  .inputFieldsssss {
    width: 2px !important;
  }
  .startBtn {
    width: 70% !important;
    position: relative;
    left: -35px !important;
  }
}

@media (max-width: 480px) {
}

@media (max-width: 445px) {
  .expart-team1 {
    font-size: 10px !important;
  }
  .title99 {
    margin-top: -50px;
  }

  .about-us56 {
    font-size: 18px !important;
  }
}

@media (max-width: 425px) {
  .title99 {
    margin-top: -100px;
  }
}

@media (max-width: 331px) {
  .title99 {
    margin-top: -150px;
    margin-left: 30px;
  }
}
@media (max-width: 650px) {
  .innerServices59 {
    margin-top: 50px !important;
  }
}
@media (max-width: 555px) {
  .companiessss img {
    /* margin-top: 50px; */
  }
}

@media (max-width: 926px) {
  .innerServices59content {
    margin-bottom: 60px !important;
  }
}

@media (max-width: 423px) {
  .innerServices59content {
    margin-bottom: 100px !important;
  }
}
@media (max-width: 381px) {
  .innerServices59content {
    /* margin-bottom: 150px !important; */
  }
}

@media (max-width: 312px) {
  .innerServices59content {
    margin-bottom: 180px !important;
  }
}

@media (max-width: 309px) {
  .innerServices59content {
    margin-bottom: 220px !important;
  }
}

@media (max-width: 282px) {
  .innerServices59content {
    margin-bottom: 250px !important;
  }
}

@media (max-width: 274px) {
  .innerServices59content {
    margin-bottom: 280px !important;
  }
}

.stats {
  font-family: sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 30px;
  padding: 40px;
  flex-wrap: wrap;
  border-top: 1px solid #a3a3a3;
}

.companiessss {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.companiessss img {
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 100px; */
}

.digit {
  color: #5c36f9;
  font-size: 60px;
  font-family: sans-serif;
}

.text {
  text-align: center;
  font-weight: 100;
}

.assignment h1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Style = {{backgroundImage:}} */

.assignmentContainer {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 955px;
  background-image: url(" https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/bg1.svg");
}

.contentHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 20px;
}

.faqRightelem img {
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.headingUpper {
  color: #6440fa;
}

.headingLower {
  color: white;
  font-size: 30px;
}

.cardssssss {
  color: white;
  display: flex;
  gap: 20px;
  margin: 0px 50px;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-around;
}

.cardElementAssignment {
  letter-spacing: 1px;
  padding-right: 30px;
  border-right: 0.5px solid #393a52;
}

.cards1 {
  display: flex;
}

.cards2 {
  display: flex;
}

@media (max-width: 1537px) {
  .cards1,
  .cards2 {
    flex-direction: column !important;
  }

  .bor1 {
    border-right: 0px;
  }

  .padd0 {
    padding-left: 0px !important;
    margin-top: 50px;
  }
  .padds {
    padding-top: 30px;
  }
  .padd1 {
    margin-top: 20px;
  }
}

@media (max-width: 839px) {
  .padd0 {
    padding-left: 0px !important;
    margin-top: 70px;
  }
}

@media (max-width: 833px) {
  .padd0 {
    padding-left: 0px !important;
    margin-top: 95px;
  }
}

@media (max-width: 788px) {
  .cardssssss {
    flex-direction: column;
  }

  .padd0 {
    margin-top: 20px;
  }

  .padd {
    padding-left: 0px !important;
  }

  .assignmentContainer {
    height: 1355px;
  }

  .headingUpper {
    font-size: 15px;
  }

  .headingLower {
    font-size: 25px;
  }

  .cardContent {
    text-align: justify;
  }
}

.get-only-new-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (max-width: 1246px) {
  .faqContent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .faqRight {
    height: 400px;
    width: 100%;
  }
  .elem1content {
    line-height: 2;
    padding-left: 30px;
    padding-right: 30px;
  }
  .elem1Heading {
    margin-top: 40px;
    font-weight: 500;
    padding-left: 20px;
    /* width: 80px !important; */
  }
}

@media (max-width: 1000px) {
  .faqLeft,
  .faqRight {
    width: 100% !important;
  }
}

@media (max-width: 722px) {
  .faqContent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .faqRight {
    height: 400px;
    width: 100%;
  }
  .elem1content {
    /* height: 344px !important; */
    line-height: 2;
    padding-left: 30px;
    padding-right: 30px;
  }
  .elem1Heading {
    margin-top: 40px;
    font-weight: 500;
    padding-left: 20px;
    /* height: 100px !important; */
  }
}

@media (max-width: 722px) {
  .faqContent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .faqRight {
    height: 400px;
    width: 100%;
  }
  .elem1content {
    /* height: 344px !important; */
    line-height: 2;
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify !important;
  }
  .elem1Heading {
    margin-top: 40px;
    font-weight: 500;
    padding-left: 20px;
    /* height: 100px !important; */
  }
}

@media (max-width: 1246px) {
  .assignmentContainer {
    height: 1355px;
  }

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}
@media (max-width: 505px) {
  /* .assignmentContainer {
    height: 1555px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}
@media (max-width: 455px) {
  /* .assignmentContainer {
    height: 1699px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}
@media (max-width: 426px) {
  /* .assignmentContainer {
    height: 1799px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}
@media (max-width: 410px) {
  /* .assignmentContainer {
    height: 1899px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}

@media (max-width: 402px) {
  .assignmentContainer {
    height: 1699px;
  }
  .cardContent {
    width: 220px !important;
  }
  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}

@media (max-width: 386px) {
  /* .assignmentContainer {
    height: 2199px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}

@media (max-width: 370px) {
  /* .assignmentContainer {
    height: 2399px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}

@media (max-width: 367px) {
  /* .assignmentContainer {
    height: 2599px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 20px;
  }
}
@media (max-width: 355px) {
  .headingLower {
    font-size: 20px;
    /* padding-left: 50px; */
  }
}

@media (max-width: 350px) {
  /* .assignmentContainer {
    height: 2699px;
  } */

  .headingUpper {
    font-size: 12px;
  }

  .headingLower {
    font-size: 15px;
  }
}

@media (max-width: 1166px) {
  .testiContent p {
    font-size: 25px;
    padding: 0 150px !important;
    letter-spacing: 2px !important;
    line-height: 1.5;
    margin-top: 30px;
  }

  .heading {
    font-size: 35px !important;
    margin-top: 30px;
  }
}
@media (max-width: 922px) {
  .testiContent p {
    font-size: 25px;
    padding: 0 50px !important;
    letter-spacing: 2px !important;
    line-height: 1.5;
    margin-top: 30px;
  }
  .heading {
    font-size: 33px !important;
    margin-top: 30px;
  }
}

@media (max-width: 700px) {
  .testiContent p {
    font-size: 22px !important;
    padding: 0 50px !important;
    letter-spacing: 2px !important;
    line-height: 1.5;
    margin-top: 30px;
  }
  .heading {
    font-size: 30px !important;
    margin-top: 30px;
  }
}

@media (max-width: 1200px) {
  .companiessss img {
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 150px; */
  }
}

@media (max-width: 437px) {
  .testiContent p {
    font-size: 15px !important;
    padding: 0 30px !important;
    letter-spacing: 2px !important;
    line-height: 1.2;
    margin-top: 20px;
  }

  .heading {
    font-size: 25px !important;
    margin-top: 30px;
  }
}

@media (max-width: 437px) {
  .heading {
    font-size: 25px !important;
    margin-top: 30px;
  }
}

@media (max-width: 355px) {
  .heading {
    font-size: 20px !important;
    margin-top: 30px;
  }
}

@media (max-width: 310px) {
  .heading {
    font-size: 15px !important;
    margin-top: 30px;
  }
}
@media (max-width: 437px) {
  .testiContent p {
    text-align: justify !important;
  }
}

.padd {
  padding-left: 0px;
}

.headingCardElement {
  font-size: 19px;
  font-weight: 100;
  margin-bottom: 20px;
}

.cardContent {
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 2;
  width: 320px;
}

/* 0 4px 4px rgba(0,0,0,.07) */

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6440fa;
  font-style: italic;
  font-size: 40px;
  margin-top: 30px;
}

.testiContent p {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 25px;
  padding: 0 250px;
  letter-spacing: 3px;
  line-height: 1.5;
  margin-top: 30px;
}

.faqHeading {
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faqUHead {
  color: #6440fa;
  letter-spacing: 2px;
}

.faqLHead {
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 10px;
}

.examHeading {
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.examHUp {
  color: #6440fa;
  letter-spacing: 2px;
}

.examHLow {
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 10px;
}

.examCards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
  text-align: center;
  font-size: 25px;
  margin-bottom: 40px;
}

.faqContent {
  display: flex;
}
.faqP {
  font-size: 15px;
}
.faqLeft {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  justify-content: center;
}

.elem1Heading {
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-left: 20px;
  color: white;
  border: none;
  width: 560px;
  height: 50px;
  background-color: #6440fa;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.elem1content {
  background-color: #f6f6f6;
  color: #a3a3a3;
  height: 120px;
  display: flex;
  line-height: 1.5;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.elem2 {
  margin-top: 30px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #f6f6f6;
  display: flex;
  padding-left: 20px;
  font-size: 15px;
  align-items: center;
}

.faqRight {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faqRightelem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  background-color: #a3a3a3;
}
.inputFieldddd {
  position: relative;
  top: -100px;
}
.assignmentHelpSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  margin: 100px 0px;
}

.aHWrapperHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.aHWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.aHWrapperHeadingUpper {
  color: #6440fa;
  letter-spacing: 2px;
  font-size: 14px;
}

.aHWrapperHeadingLower {
  font-size: 30px;
}

.aHWrapperbuttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.aHWrapperbuttons button {
  border: none;
  width: 100px;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
}

.aHSection3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 80%;
  height: 100%;
}

.aHSection3Left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aHSection3LeftImg {
  width: 80%;
  background-color: #a3a3a3;
  height: 500px;
  border-radius: 10px;
}
.aHSection3Right {
  width: 50%;
}

.aHSection3RightContent {
  width: 100%;
  padding: 100px;
}

.aHSection3RightContent li {
  padding: 10px 0px;
}

.mainFormWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-inputt {
  width: 370px;
  height: 50px;
  border: 1px solid rgba(26, 6, 78, 0.07);
  background-color: #f3f7fa;
  border-radius: 10px;
  margin-bottom: 20px;
}
.formLastFieldd {
  width: 100%;
  height: 100px;
  border: 1px solid rgba(26, 6, 78, 0.07);
  background-color: #f3f7fa;
  border-radius: 10px;
}

.date::-ms-input-placeholder {
  margin: 30px !important;
}

.formWrapperrr {
  border-radius: 20px;
  display: flex;
  width: 420px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  display: none;
}

@media (max-width: 799px) {
  .formWrapperrr {
    border-radius: 20px;
    display: flex;
    /* width: 400px !important; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    display: none;
  }
}
@media (max-width: 450px) {
  #date {
    width: 220px !important;
  }
  .formWrapperrr {
    width: 250px !important;
  }

  .spanDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .formWrapperrr {
    margin-bottom: 150px;
  }
}

@media (max-width: 425px) {
  .formWrapperrr {
    margin-bottom: 150px;
  }
}

@media (max-width: 324px) {
  .formWrapperrr {
    margin-bottom: 200px;
  }
}
@media (max-width: 1180px) {
  .aHSection3RightContent {
    width: 100%;
    padding: 0px !important;
  }
}

@media (max-width: 975px) {
  .aHSection3 {
    flex-direction: column;
  }
  .aHSection3Left {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .aHWrapperbuttons {
    flex-wrap: wrap;
  }
}

@media (max-width: 494px) {
  .formWrapperrr {
    border-radius: 20px;
    display: flex;
    /* width: 400px !important; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    display: none;
  }

  .aHWrapperHeadingLower {
    font-size: 20px;
  }
}

@media (max-width: 1340px) {
  .formWrapperrr {
    border-radius: 20px;
    display: flex;
    background-color: #f3f3f3;
    flex-direction: column;
    align-items: center;
  }
  .title99 {
    margin-top: 150px !important;
  }
}

@media (max-width: 280px) {
  .formWrapperrr {
    border-radius: 20px;
    display: flex;
    background-color: #f3f3f3;
    flex-direction: column;
    align-items: center;
  }
  .title99 {
    margin-top: 150px !important;
  }
}

#date {
  border: 1px solid rgba(26, 6, 68, 0.07);
  background-color: #f3f7fa;
  display: block;
  margin-bottom: 20px;
  padding: 15px 25px;
  width: 370px;
  outline: none;
  color: #a3a3a3;
  border-radius: 10px;
  text-align: start;
  transition: 250ms width ease, 250ms border-color ease;
}
/************** 
     On Hover
  ***************/
/* #date:hover{
    width: 390px;
  } */
/************** 
     On Focus
  ***************/
#date:focus {
  width: 380px;
  border-color: #6fb98f;
}

li {
  list-style: none;
  position: relative;
}

.elem1 {
  width: 560px;
  height: 70px;
}

@media (max-width: 800px) {
  .elem1Heading,
  .elem1 {
    width: 460px;
    font-size: 12px;
    padding-right: 20px;
  }
  .elem1content {
    height: 120px;
    width: 460px;
  }
}

@media (max-width: 600px) {
  .elem1Heading,
  .elem1 {
    width: 360px;
  }
  .inputFieldsssss {
    width: 195px !important;
  }
  .elem1content {
    height: 140px;
    width: 360px;
  }
}
@media (max-width: 500px) {
  .elem1Heading,
  .elem1 {
    width: 360px;
  }
  .elem1 {
    margin-bottom: 20px;
  }
}
@media (max-width: 380px) {
  .inputFieldsssss {
    width: 105px !important;
  }
}
@media (max-width: 400px) {
  .elem1Heading,
  .elem1 {
    width: 280px;
  }
  .elem1content {
    height: 180px;
    width: 280px;
  }
  .elem1 {
    margin-bottom: 60px;
  }
}

@media (max-width: 320px) {
  .elem1Heading,
  .elem1 {
    width: 240px;
  }
  .elem1content {
    height: 225px;
    width: 240px;
  }
  .elem1 {
    margin-bottom: 90px;
  }

  .elem1Heading {
    margin: 0;
    padding: 0;
  }
}

/**************************************************** */

* {
  margin: 0;
  padding: 0;
}

.formBox {
  width: 460px;
  height: 670px;
  position: relative;
  margin: 6% auto;
  background-color: #ebebeb;
  padding: 5px;
  overflow: hidden;
}

.formBox2 {
  width: 460px;
  height: 680px;
  position: relative;
  margin: 6% auto;
  background-color: #ebebeb;
  padding: 5px;
  overflow: hidden;
}

/* .buttonBox{
    width: 410px;
    margin: 5px auto;
    position: relative;
    border-bottom: 1px solid #6440fa;
    box-shadow: 0 0 20px 9px #0000001f;
    border-radius: 30px;
    position: relative;
    top: 30px;
    display:flex;
    align-items:center;
    justify-content:center;
} */

.toggleBtn {
  padding: 10px 20px;
  cursor: pointer;
  background: transparent;
  border: 0;
  color: black;
  outline: none;
  position: relative;
}
.toggleBtnn {
  padding: 10px 20px;
  cursor: pointer;
  background: transparent;
  border: 0;
  color: black;
  outline: none;
  position: relative;
}
.toggleBtnnn {
  padding: 10px 20px;
  cursor: pointer;
  background: transparent;
  border: 0;
  color: black;
  outline: none;
  position: relative;
}
.hero {
  position: relative;
  right: 80px;
  top: 10px;
}

#btn {
  top: 0;
  left: 15;
  position: absolute;
  width: 120px;
  height: 110%;
  background: linear-gradient(to right, #9885e4, #4c33b2);
  border-radius: 30px;
  transition: 0.5s ease;
}
#btnn {
  top: 0;
  left: 15;
  position: absolute;
  width: 120px;
  height: 110%;
  background: linear-gradient(to right, #9885e4, #4c33b2);
  border-radius: 30px;
  transition: 0.5s ease;
}

.inputGroup {
  top: 100px;
  position: absolute;
  transition: 0.5s;
  color: #a3a3a3;
  display: block;
  /* margin-bottom: 20px ; */
  /* padding: 15px 45px; */
  width: 370px;
  outline: none;
  color: #a3a3a3;
  border-radius: 10px;
  text-align: start;
  /* transition: 250ms width ease, 250ms border-color ease; */
}

.inputGroupp {
  top: 100px;
  position: absolute;
  transition: 0.5s;
  color: #a3a3a3;
  display: block;
  /* margin-bottom: 20px ; */
  /* padding: 15px 45px; */
  width: 370px;
  outline: none;
  color: #a3a3a3;
  border-radius: 10px;
  text-align: start;
  /* transition: 250ms width ease, 250ms border-color ease; */
}

.inputField {
  border: 1px solid rgba(26, 6, 68, 0.07);
  background-color: #fff;
  display: block;
  background-position: calc(100% - 20px) center; /* Adjust the value to position the arrow */
  -webkit-appearance: none;

  margin-bottom: 20px;
  padding: 15px 25px;
  width: 370px;
  outline: none;
  color: #7f7f7f;
  border-radius: 10px;
  text-align: start;
}

.startBtn {
  width: 100%;
  color: white;
  padding: 10px 30px;
  cursor: pointer;
  display: block;
  margin: auto;
  border: none;
  border-radius: 10px;
  background: #01ff85;
}

#assignment {
  left: 45px;
}

#exams {
  left: 490px;
}

.okokok {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}

/* styles.css */
.inputFieldd::placeholder {
  font-style: italic;
  color: blue;
}

/* dropdown button */
.dropbtn {
  background: linear-gradient(to right, #9885e4, #4c33b2);
  color: white;
  border-radius: 20px;
  padding: 16px;
  position: relative;
  top: 15px;
  width: 380px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: linear-gradient(to right, #9885e4, #4c33b2);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
  color: black;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.ablack:hover {
  color: black;
  background-color: black;
}

/* button popup */

.startBtn {
  cursor: pointer;
  width: 100%;
  color: white;
  padding: 10px 30px;
  cursor: pointer;
  display: block;
  text-align: center;
  margin: auto;
  border: none;
  border-radius: 10px;
  background: #01ff85;
}

.custom-model-main {
  text-align: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; /* z-index: 1050; */
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear, z-index 0.15;
  -o-transition: opacity 0.15s linear, z-index 0.15;
  transition: opacity 0.15s linear, z-index 0.15;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: auto;
}

.model-open {
  z-index: 99999;
  opacity: 1;
  overflow: hidden;
}
.custom-model-inner {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: inline-block;
  vertical-align: middle;
  width: 600px;
  margin: 30px auto;
  max-width: 97%;
}
.custom-model-wrap {
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
  text-align: left;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
}
.model-open .custom-model-inner {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  position: relative;
  z-index: 999;
}
.model-open .bg-overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}
.bg-overlay {
  background: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transition: background 0.15s linear;
  -o-transition: background 0.15s linear;
  transition: background 0.15s linear;
}
.close-btn {
  position: absolute;
  right: 0;
  top: -30px;
  cursor: pointer;
  z-index: 99;
  font-size: 30px;
  color: #fff;
}

@media screen and (min-width: 800px) {
  .custom-model-main:before {
    content: "";
    display: inline-block;
    height: auto;
    vertical-align: middle;
    margin-right: -0px;
    height: 100%;
  }
}
@media screen and (max-width: 799px) {
  .custom-model-inner {
    margin-top: 45px;
  }
}
