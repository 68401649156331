.tableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  /* background: black; */
  z-index: -1000;
  flex-wrap: wrap;
}

table {
  /* margin-top: 300px; */
  margin: 50px 0px;
  border-radius: 20px;
  text-align: center;
  color: white;
  width: 400px;
  border: 1px solid white;
  line-height: 4;
  background: linear-gradient(
    to right bottom,
    rgba(40, 84, 155, 0.8),
    rgba(35, 85, 130, 0.3)
  );
  border-radius: 2rem;
}

.circle1 {
  top: 90%;
}

@media (max-width: 1897px) {
  .circle1 {
    margin-top: 300px;
  }
}

@media (max-width: 1813px) {
  .circle1 {
    margin-top: 270px;
  }
}

@media (max-width: 1740px) {
  .circle1 {
    margin-top: 250px;
  }
}

@media (max-width: 1700px) {
  .circle1 {
    margin-top: 230px;
  }
}

@media (max-width: 1675px) {
  .circle1 {
    margin-top: 200px;
  }
}

@media (max-width: 1609px) {
  .circle1 {
    margin-top: 150px;
  }
}

@media (max-width: 1535px) {
  .circle1 {
    margin-top: 50px;
    height: 150%;
  }

  .tableContainer {
    gap: 50px;
  }
}

@media (max-width: 1341px) {
  .circle1 {
    /* margin-top: 200px; */

    /* height: 190%; */
  }
}

@media (max-width: 1210px) {
  .circle1 {
    position: absolute;
    top: 900px;
  }
}

@media (max-width: 1298px) {
  .circle1 {
    height: 300%;
    margin-top: 150px;
  }

  .tableContainer {
    gap: 50px;
  }

  .threetable {
    margin-top: 0px;
  }
  .tabletable,
  .twotable,
  .threetable {
    /* margin-top: 400px; */
  }
}

@media (max-width: 1210px) {
  .circle1 {
    height: 300%;
    margin-top: 0px;
  }

  .tableContainer {
    gap: 50px;
  }

  .threetable {
    margin-top: 0px;
  }
  .tabletable,
  .twotable,
  .threetable {
    /* margin-top: 400px; */
  }
}

@media (max-width: 1160px) {
  .circle1 {
    height: 300%;
  }

  .tableContainer {
    gap: 50px;
  }

  .threetable {
    margin-top: 0px;
  }
  .tabletable .twotable {
    margin-top: 450px;
  }
}

@media (max-width: 1155px) {
  .circle1 {
    height: 300%;
  }

  .tableContainer {
    gap: 50px;
  }

  .threetable {
    margin-top: 0px;
  }

  .tabletable,
  .twotable {
    /* margin-top: 400px; */
  }

  .circle1 {
    position: absolute;
    top: 850px;
  }
}

@media (max-width: 1080px) {
  .circle1 {
    position: absolute;
    top: 800px;
  }
}

@media (max-width: 1045px) {
  .circle1 {
    height: 300%;
  }

  .tableContainer {
    gap: 50px;
  }

  .threetable {
    margin-top: 0px;
  }
}

@media (max-width: 950px) {
  .circle1 {
    height: 300%;
  }

  .tableContainer {
    gap: 50px;
  }

  .threetable {
    margin-top: 0px;
  }
}

@media (max-width: 970px) {
  .circle1 {
    position: absolute;
    top: 750px;
  }
}

@media (max-width: 880px) {
  .tableContainer {
    flex-direction: column;
    gap: 50;
  }

  .twotable {
    margin-top: 0px;
  }

  .circle1 {
    height: 400%;
  }
}

@media (max-width: 840px) {
  .circle1 {
    position: absolute;
    top: 600px;
  }

  .tabletable {
    /* margin-top: 250px; */
  }
}

@media (max-width: 790px) {
  .circle1 {
    position: absolute;
    top: 500px;
  }

  .tabletable {
    /* margin-top: 200px; */
  }
}

@media (max-width: 700px) {
  .circle1 {
    position: absolute;
    top: 420px;
  }

  .tabletable {
    /* margin-top: 150px; */
  }
}

@media (max-width: 620px) {
  .circle1 {
    position: absolute;
    top: 370px;
  }

  .tabletable {
    /* margin-top: 150px; */
  }
}

@media (max-width: 550px) {
  .circle1 {
    position: absolute;
    top: 370px;
  }

  .tabletable {
    /* margin-top: 200px; */
  }
}

@media (max-width: 550px) {
  .circle1 {
    position: absolute;
    top: 330px;
  }

  .tabletable {
    /* margin-top: 150px; */
  }
}

@media (max-width: 440px) {
  .tabletable {
    width: 350px;
    /* margin-top: 170px; */
  }
}

@media (max-width: 397px) {
  .tabletable {
    width: 330px;
    margin-left: -10px;
    margin-top: 180px;
  }
}

@media (max-width: 370px) {
  .tabletable {
    width: 310px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 360px) {
  .tabletable {
    width: 300px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 355px) {
  .tabletable {
    width: 270px;
    margin-left: -20px;
    margin-top: 50px;
  }
}

@media (max-width: 349px) {
  .tabletable {
    width: 270px;
    margin-left: -20px;
    margin-top: 50px;
  }
}

@media (max-width: 325px) {
  .tabletable {
    width: 270px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 316px) {
  .tabletable {
    width: 270px;
    margin-left: -10px;
    margin-top: 50px;
  }
}
@media (max-width: 309px) {
  .tabletable {
    width: 250px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 303px) {
  .tabletable {
    width: 240px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 299px) {
  .tabletable {
    width: 240px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 284px) {
  .tabletable {
    width: 240px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 274px) {
  .tabletable {
    width: 240px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

@media (max-width: 264px) {
  .tabletable {
    width: 240px;
    margin-left: -10px;
    margin-top: 50px;
  }
}

/* **************************************


***************table two*****************


*****************************************/

@media (max-width: 440px) {
  .twotable {
    width: 350px;
  }
}

@media (max-width: 397px) {
  .twotable {
    width: 330px;
    margin-left: -10px;
  }
}

@media (max-width: 370px) {
  .twotable {
    width: 310px;
    margin-left: -10px;
  }
}

@media (max-width: 360px) {
  .twotable {
    width: 300px;
    margin-left: -10px;
  }
}

@media (max-width: 355px) {
  .twotable {
    width: 270px;
    margin-left: -20px;
  }
}

@media (max-width: 349px) {
  .twotable {
    width: 270px;
    margin-left: -20px;
  }
}

@media (max-width: 325px) {
  .twotable {
    width: 270px;
    margin-left: -10px;
  }
}

@media (max-width: 316px) {
  .twotable {
    width: 270px;
    margin-left: -10px;
  }
}

@media (max-width: 309px) {
  .twotable {
    width: 250px;
    margin-left: -10px;
  }
}

@media (max-width: 303px) {
  .twotable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 299px) {
  .twotable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 284px) {
  .twotable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 274px) {
  .twotable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 264px) {
  .twotable {
    width: 240px;
    margin-left: -10px;
  }
}

/* **************************************


***************table three*****************


*****************************************/

@media (max-width: 440px) {
  .threetable {
    width: 350px;
  }
}

@media (max-width: 397px) {
  .threetable {
    width: 330px;
    margin-left: -10px;
  }
}

@media (max-width: 370px) {
  .threetable {
    width: 310px;
    margin-left: -10px;
  }
}

@media (max-width: 360px) {
  .threetable {
    width: 300px;
    margin-left: -10px;
  }
}

@media (max-width: 355px) {
  .threetable {
    width: 270px;
    margin-left: -20px;
  }
}

@media (max-width: 349px) {
  .threetable {
    width: 270px;
    margin-left: -20px;
  }
}

@media (max-width: 349px) {
  .threetable {
    width: 270px;
    margin-left: -20px;
  }
}

@media (max-width: 325px) {
  .threetable {
    width: 270px;
    margin-left: -10px;
  }
}

@media (max-width: 316px) {
  .threetable {
    width: 270px;
    margin-left: -10px;
  }
}

@media (max-width: 309px) {
  .threetable {
    width: 250px;
    margin-left: -10px;
  }
}

@media (max-width: 303px) {
  .threetable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 299px) {
  .threetable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 284px) {
  .threetable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 274px) {
  .threetable {
    width: 240px;
    margin-left: -10px;
  }
}

@media (max-width: 264px) {
  .threetable {
    width: 240px;
    margin-left: -10px;
  }
}
