* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  justify-content: space-evenly;
}

.section64 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 30px;
  font-family: sans-serif;
  letter-spacing: 1px;
  padding-left: 300px;
  width: 50%;
  line-height: 1.5;
  position: relative;
  top: -40px;
  left: -200px;
}
@media (max-width: 1060px) {
  .section64 {
    /* top: 100px !important;
    left: 0px !important; */
    /* padding-left: 200px !important; */
  }
}
.section64 h1 {
  font-weight: 400;
}

.section64 h6 {
  font-weight: 200;
}

.section2 {
  width: 50%;
}
@media (max-width: 600px) {
  /* .section64 h1 {
    margin-left: -180px !important;
    position: relative;
    left: -40px;
  }

  .section64 h6 {
    margin-left: -180px !important;
    position: relative;
    left: -40px;
  } */
}
@media (max-width: 1450px) {
  /* .section65 h1 {
    margin-left: -180px;
  }

  .section65 h6 {
    margin-left: -180px;
  } */

  /* .section64 {
    padding-left: 500px !important;
  } */
}
/* @media (max-width: 1060px) {
  .section64 h1 {
    margin-left: -380px !important;
  }

  .section64 h6 {
    margin-left: -380px !important;
  }
} */
@media (max-width: 944px) {
}

@media (max-width: 860px) {
  .section64 h1 {
    font-size: 50px;
  }

  .section64 h6 {
    font-size: 20px;
  }
}

/* @media (max-width: 800px) {
  .section65 {
    margin-top: 80px;
  }
} */

@media (max-width: 860px) {
  .section64 h1 {
    font-size: 40px;
  }

  .section64 h6 {
    font-size: 15px;
    width: 200px;
  }
}

/* @media (max-width: 633px) {
  .section65 h1 {
    font-size: 30px;
  }

  .section65 h6 {
    font-size: 10px;
  }
} */

@media (max-wdth: 600px) {
  .section64 h6{
    width: 200px !important;
  }
}

@media (max-width: 500px) {
  /* .section65 {
    margin-top: 140px;
    padding-left: 50px;
  } */
  .section64 h6 {
    font-size: 3vw;
  }

  .section64 h1 {
    margin-left: 0px;
  }

  .section64 h6 {
    margin-left: 0px;
  }
}

@media (max-width: 460px) {
  .section64 h1 {
    font-size: 25px;
  }

  /* .section64 h6 {
    font-size: 8px;
  } */
}

@media (max-width: 370px) {
  .section64 h1 {
    font-size: 19px;
  }

  /* .section64 h6 {
    font-size: 6px;
  } */
}

@media (max-width: 450px) {
  .section64 {
    margin-top: 100px;
  }
}

@media (max-width: 390px) {
  .section64 {
    margin-top: 110px;
  }

  .section64 h1 {
    margin-left: -10px;
  }

  .section64 h6 {
    margin-left: -10px;
  }
}
