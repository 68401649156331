.headerMain{
    z-index: 1;
    width: 100%;
}

.headerLeft{
    width: 50%;

}
.headerRight{
    width: 50%;
}

/*       "src": "favicon.ico",
 */