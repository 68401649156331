.section1Adm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 800px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
}

.admissionWrapper {
  margin-top: 200px;
}

.section1AdmRight {
  padding-left: 200px;
  width: 50vw;
}

.section1Adm backgroundimg {
  width: 20px;
}

.section1AdmRight h1 {
  font-size: 4rem;
  margin-bottom: 0.8rem;
}

.section1AdmRight p {
  font-size: 1.6rem;
  line-height: 2.5rem;
  letter-spacing: 2px;
  font-weight: 200;
}

.button {
  display: flex;
  margin-top: 20px;
}

.role {
  background: transparent;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  font-weight: 800;
  border: 0.15rem solid black;
  border-radius: 30px;
  height: 60px;
  width: 180px;
  color: #0000d3;
}

.role1 {
  margin-right: 20px;
  background-color: #7855c3;
  color: white;
  border: none;
}

/* section2 */

.section2Adm {
  display: flex;
  margin-bottom: 80px;
  margin-top: 80px;
}

.section2Adm1 {
  width: 50%;
  font-size: 22px;
  padding-left: 200px;
}

.section2Adm2 {
  width: 50%;
  font-size: 22px;
  font-weight: 200;
  line-height: 1.5;
  letter-spacing: 1.2px;
  padding-right: 200px;
}

/* section 3 */

.img-innerrrr img {
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.section3Adm11Logo {
  width: 200px;
  height: 200px;
  margin-right: 60px;
  margin-left: 100px;
  background-color: #c4c4c4;
  border-radius: 50%;
  /* border-radius: 50%; */

  display: flex;
  /* position: relative; */

  /* border-radius: 50%; */
  /* margin-left: 300px; */
}

.section3Adm12Logo {
  padding: 130px 0;
  padding-left: 130px;
  padding-right: -100px;
  margin-right: 100px;
}
.section3AdmWrapper {
  width: 100%;
}

.section3Adm11 {
  display: flex;
  align-items: center;
  width: 50%;
  border-bottom: 1px dotted black;
  border-right: 1px dotted black;
}

.section3Adm12 {
  display: flex;
  align-items: center;
  width: 50%;
  border-bottom: 1px dotted black;
}

.section3Adm1 {
  display: flex;
  align-items: center;
}

.section61SubH {
  width: 50%;
  letter-spacing: 3px;
  font-size: 12px;
  line-height: 2;
}

.section62SubH {
  width: 100%;
  letter-spacing: 3px;
  font-size: 12px;
  line-height: 2;
}

.section63SubH {
  width: 50%;
  letter-spacing: 3px;
  font-size: 12px;
  line-height: 2;
}

.section63SubH {
  width: 100%;
  letter-spacing: 3px;
  font-size: 13px;
  line-height: 2;
}
.section3Adm11Content {
  padding-right: -200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 350px;
  padding-top: 90px;
  width: 60%;
}

.section3Adm11ContentHeading {
  font-size: 30px;
}

.section3Adm11ContentP {
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 80%;
  line-height: 1.5;
}

.section3Adm2 {
  display: flex;
  align-items: center;
}

.section3Adm21 {
  display: flex;
  align-items: center;
  width: 50%;
  border-bottom: 1px dotted black;
  border-right: 1px dotted black;
}

/*   */

.section3Adm21Logo {
  padding: 130px 0;
  padding-left: 130px;
  padding-right: -100px;
  margin-right: 100px;
}

.section3Adm21Content {
  padding-right: -200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 350px;
  padding-top: 90px;
}

.section3Adm21ContentHeading {
  font-size: 30px;
}

.section3Adm21ContentP {
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 80%;
  line-height: 1.5;
}

.section3Adm22 {
  display: flex;
  align-items: center;
  width: 50%;
  border-bottom: 1px dotted black;
}

.section3Adm22Logo {
  padding: 130px 0;
  padding-left: 130px;
  padding-right: -100px;
  margin-right: 100px;
}

.section3Adm22Content {
  padding-right: -200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 350px;
  padding-top: 90px;
}

.section3Adm12Content {
  padding-right: -200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 350px;
  padding-top: 90px;
}

.section3Adm12ContentP {
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 80%;
  line-height: 1.5;
}

.section3Adm22ContentHeading {
  font-size: 30px;
}

.section3Adm12ContentHeading {
  font-size: 30px;
}

.section3Adm22ContentP {
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 80%;
  line-height: 1.5;
}

.section3Adm3 {
  display: flex;
  align-items: center;
}

.section3Adm31 {
  display: flex;
  align-items: center;
  width: 50%;

  border-right: 1px dotted black;
}

.section3Adm31Logo {
  padding: 130px 0;
  padding-left: 130px;
  padding-right: -100px;
  margin-right: 100px;
}

.section3Adm31Content {
  padding-right: -200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 350px;
  padding-top: 90px;
}

.section3Adm31ContentHeading {
  font-size: 30px;
}

.section32Adm11ContentP {
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 80%;
  line-height: 1.5;
}

.section3Adm32 {
  display: flex;
  align-items: center;
  width: 50%;
}

.section3Adm32Logo {
  padding: 130px 0;
  padding-left: 130px;
  padding-right: -100px;
  margin-right: 100px;
}

.section3Adm32Content {
  padding-right: -200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 350px;
  padding-top: 90px;
}

.section3Adm32ContentHeading {
  font-size: 30px;
}

.section3Adm32ContentP {
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 80%;
  line-height: 1.5;
}

.section4Heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.section4Container {
  background-color: #ededed;
  margin-top: 100px;
}

.section4 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 100%;
  max-width: 100%;
  gap: 50px;
  padding-left: 300px;
  padding-right: 300px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.section4Heading {
  text-align: center;
}

.section4content {
  font-size: 22px;
  font-weight: 200;
  letter-spacing: 1.2px;
  text-align: center;
  line-height: 1.5;
}

.section4Stats {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.section4Stats1 {
  background-color: #0000d3;
  border-radius: 50%;
  width: 337px;
  height: 337px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: white;
}

.section4Stats12 {
  font-size: 80px;
}

.section4Stats11 {
  font-size: 20px;
}

.section4Stats13 {
  font-size: 20px;
}

.section4Stats2 {
  background-color: rgb(5, 5, 79);
  border-radius: 50%;
  width: 337px;
  height: 337px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: white;
}

.section4Stats21 {
  font-size: 20px;
}

.section4Stats23 {
  font-size: 20px;
}

.section4Stats22 {
  font-size: 80px;
}

.section4Stats3 {
  background-color: rgb(0, 161, 186);
  border-radius: 50%;
  width: 337px;
  height: 337px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: rgb(2, 2, 29);
}

.section4Stats31 {
  font-size: 20px;
}

.section4Stats33 {
  font-size: 20px;
}

.section4Stats32 {
  font-size: 80px;
}

.section5 {
  background-color: rgb(5, 5, 79);
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
  padding-left: 250px;
  padding-right: 300px;
  padding-top: 60px;
  padding-bottom: 80px;
}

.section5Heading {
  font-size: 2rem;
}

.section5ContentLogo {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section5ContentP {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.section5ContentPHeadingSub {
  font-size: 15px;
  letter-spacing: 8px;
}

.section5ContentPHeadingMain {
  font-size: 28px;
}

.section5ContentPContent {
  font-size: 38px;
  line-height: 1.5;
}

.section5ContentPBtn button {
  width: 180px;
  background-color: #7855c3;
  color: white;
  border-radius: 30px;
  font-size: 18px;
  border: none;
  height: 64px;
  font-weight: 400;
}
.buttonHome button {
  width: 180px;
  background-color: transparent;
  color: white;
  border-radius: 30px;
  font-size: 18px;
  border: 1px solid grey;
  height: 64px;
  margin-top: 20px;
  font-weight: 400;
}
.buttonHome button:hover {
  width: 180px;
  background: linear-gradient(
    90deg,
    rgba(11, 8, 62, 1) 0%,
    rgba(0, 0, 124, 1) 85%
  );
  color: white;
  border-radius: 30px;
  font-size: 18px;
  border: none;
  height: 64px;
  font-weight: 400;
  /* transition: 5000ms linear; */
}

.section6 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 60px;
  padding-bottom: 80px;
}

/* #071351 */

.section6HeadingC {
  color: #071351;
  font-size: 45px;
  font-weight: 700;
}

.section61Logo img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  /* margin-bottom:  40px; */
  margin-top: 20px;
  border-radius: 50%;
}

.section62Logo img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  /* margin-bottom:  40px; */
  margin-top: 20px;
  border-radius: 50%;
}
.section61P {
  margin-bottom: 100px;
}

.section63Logo img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  /* margin-bottom:  40px; */
  margin-top: 20px;
  border-radius: 50%;
}

.section63P {
  margin-bottom: 0px;
}

.section62P {
  margin-bottom: 30px;
}

.section62Logo {
  /* margin-bottom: 80px; */
}

.section62Logo {
  /* margin-top: 20px; */
}

.section6Div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.section61 {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 33.33%;
  padding: 0 50px;
  border-right: 1px dotted black;
}

.section62 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 33.33%;
  padding: 0 50px;
  border-right: 1px dotted black;
}

.section63 {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 33.33%;
  padding: 0 50px;
}

.section61P {
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 100%;
  line-height: 1.3;
}

.section61H {
  font-size: 30px;
  color: #071351;
}

.section62H {
  font-size: 30px;
  color: #071351;
}

.section63H {
  font-size: 30px;
  color: #071351;
}

.section62P {
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 100%;
  line-height: 1.3;
}

.section63P {
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 1.2px;
  width: 100%;
  line-height: 1.3;
}

.section63F {
  color: #081351;
  font-size: 20px;
}

.section6 {
  border-bottom: 1px dotted black;
  padding-bottom: 120px;
}

/* min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    margin-top: 20px;
    border-radius: 50%; */

.img-innerrrr img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.section5ContentLogo img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 100px;
}

/* */

.section7HEading {
  color: #071351;
  font-size: 45px;
  font-weight: 700;
}

.section7 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
  padding-left: 300px;
  padding-right: 300px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.section7Content {
  font-size: 22px;
  font-weight: 200;
  letter-spacing: 1.2px;
  line-height: 1.5;
  margin: 30px 0;
}

.section7Lower {
  display: flex;
  justify-content: space-between;
}

.section7Lower1 {
  color: #0000d3;
  font-size: 19px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section7Lower12 {
  border-right: 1px dotted black;
  padding-right: 150px;
}
@media (max-width: 1510px) {
  .section7Lower12 {
    border-right: 1px dotted black;
    padding-right: 100px;
  }
}

@media (max-width: 1350px) {
  .section7Lower12 {
    border-right: 1px dotted black;
    padding-right: 50px;
  }
}

@media (max-width: 1350px) {
  .section7Lower {
    border-right: 0px dotted black;
    padding-right: 50px;
    flex-wrap: wrap;
  }

  .section7Lower122 {
    padding-right: 50px;
  }
}

@media (max-width: 1281px) {
  .section7Lower122 {
    border-top: 1px dotted black;
    padding-right: 50px;
    margin-top: 20px;
    padding-top: 20px;
  }
}

@media (max-width: 1278px) {
  .section7Lower1 {
    border: 0;
  }
}

@media (max-width: 935px) {
  .section7 {
    padding-left: 250px;
  }
}

@media (max-width: 895px) {
  .section7 {
    padding-left: 100px;
  }
}

@media (max-width: 690px) {
  .section7 {
    padding-left: 50px;
  }
}

@media (max-width: 1650px) {
  .section4Stats1,
  .section4Stats2,
  .section4Stats3 {
    width: 270px;
    height: 270px;
    gap: 20px;
  }

  .section4Stats12,
  .section4Stats22,
  .section4Stats32 {
    font-size: 60px;
  }

  .section4Stats11,
  .section4Stats23,
  .section4Stats31 {
    font-size: 15px;
  }

  .section4Stats13,
  .section4Stats21,
  .section4Stats33 {
    font-size: 15px;
  }
}

@media (max-width: 1280px) {
  .section4Stats1,
  .section4Stats2,
  .section4Stats3 {
    width: 230px;
    height: 230px;
    gap: 20px;
  }

  .section4Stats12,
  .section4Stats22,
  .section4Stats32 {
    font-size: 50px;
  }

  .section4Stats11,
  .section4Stats23,
  .section4Stats31 {
    font-size: 13px;
  }

  .section4Stats13,
  .section4Stats21,
  .section4Stats33 {
    font-size: 13px;
  }
}

@media (max-width: 1100px) {
  .section4Stats1,
  .section4Stats2,
  .section4Stats3 {
    width: 190px;
    height: 190px;
    gap: 20px;
  }

  .section4Stats12,
  .section4Stats22,
  .section4Stats32 {
    font-size: 40px;
  }

  .section4Stats11,
  .section4Stats23,
  .section4Stats31 {
    font-size: 10px;
  }

  .section4Stats13,
  .section4Stats21,
  .section4Stats33 {
    font-size: 10px;
  }
}

@media (max-width: 1450px) {
  .section4Stats1,
  .section4Stats2,
  .section4Stats3 {
    width: 180px;
    height: 180px;
    gap: 20px;
  }

  .section4Stats12,
  .section4Stats22,
  .section4Stats32 {
    font-size: 35px;
  }

  .section4Stats11,
  .section4Stats23,
  .section4Stats31 {
    font-size: 10px;
  }

  .section4Stats13,
  .section4Stats21,
  .section4Stats33 {
    font-size: 10px;
  }
}

@media (max-width: 1100px) {
  .section4Stats1,
  .section4Stats2,
  .section4Stats3 {
    width: 170px;
    height: 170px;
    gap: 30px;
  }

  .section4Stats12,
  .section4Stats22,
  .section4Stats32 {
    font-size: 32px;
  }

  .section4Stats11,
  .section4Stats23,
  .section4Stats31 {
    font-size: 8px;
  }

  .section4Stats13,
  .section4Stats21,
  .section4Stats33 {
    font-size: 8px;
  }

  .section4Stats {
    gap: 30px;
  }
}

@media (max-width: 1520px) {
  .section4 {
    padding-left: 300px;
  }
}

@media (max-width: 1370px) {
  .section4 {
    padding-left: 270px;
  }
}

@media (max-width: 1220px) {
  .section4 {
    padding-left: 240px;
  }

  .section4Heading {
    font-size: 40px;
  }

  .section4content {
    font-size: 18px;
  }
}

@media (max-width: 1010px) {
  .section4 {
    padding-left: 210px;
  }

  .section4Heading {
    font-size: 35px;
  }

  .section4content {
    font-size: 15px;
  }
}

@media (max-width: 1010px) {
  .section4 {
    padding-left: 210px;
  }

  .section4Heading {
    font-size: 35px;
  }

  .section4content {
    font-size: 15px;
  }
}

@media (max-width: 940px) {
  .section4 {
    padding-left: 190px;
    gap: 20px;
  }

  .section4Heading {
    font-size: 35px;
  }

  .section4content {
    font-size: 15px;
  }
}

@media (max-width: 870px) {
  .section4 {
    padding-left: 150px;
    gap: 20px;
  }

  .section4Heading {
    font-size: 35px;
  }

  .section4content {
    font-size: 15px;
  }
}

@media (max-width: 780px) {
  .section4 {
    padding-left: 130px;
    gap: 15px;
  }

  .section4Heading {
    font-size: 35px;
  }

  .section4content {
    font-size: 15px;
  }
}

@media (max-width: 710px) {
  .section4 {
    padding-left: 60px;
    gap: 10px;
  }

  .section4Heading {
    font-size: 35px;
  }

  .section4content {
    font-size: 15px;
  }
}

@media (max-width: 630px) {
  .section4 {
    padding-left: 40px;
    gap: 6px;
  }

  .section4Heading {
    font-size: 30px;
  }

  .section4content {
    font-size: 12px;
  }
}

@media (max-width: 605px) {
  .section4 {
    padding-left: 100px;
    gap: 6px;
  }

  .section4Heading {
    font-size: 30px;
    text-align: left;
    width: 20vw;
  }

  .section4content {
    font-size: 20px;
    text-align: left;
    margin-left: -10px;
  }

  .section4Stats {
    flex-wrap: wrap;
  }
}

@media (max-width: 560px) {
  .section4 {
    padding-left: 170px;
    gap: 6px;
  }

  .section4Heading {
    font-size: 30px;
  }

  .section4content {
    font-size: 20px;
    margin-left: -10px;
  }

  .section4Stats {
    flex-wrap: wrap;
  }
}

@media (max-width: 470px) {
  .section4 {
    padding-left: 150px;
    gap: 6px;
  }

  .section4Heading {
    font-size: 30px;
  }

  .section4content {
    font-size: 20px;
    margin-left: -20px;
  }

  .section4Stats {
    flex-wrap: wrap;
  }
}

@media (max-width: 430px) {
  .section4 {
    padding-left: 90px !important;
    gap: 6px;
  }

  .section4Heading {
    font-size: 30px;
    margin-left: -50px !important;
    width: 300px !important;
  }

  .section4Stats {
    flex-wrap: wrap;
    margin-left: -80px !important;
  }

  .section4content {
    font-size: 20px;
    margin-left: -50px !important;
    margin-top: 20px;
    height: 320px;
  }
}

@media (max-width: 390px) {
  .section4 {
    padding-left: 90px !important;
    gap: 6px;
  }

  .section4Heading {
    font-size: 30px;
    margin-left: -50px !important;
    width: 300px !important;
  }

  .section4Stats {
    flex-wrap: wrap;
    margin-left: -80px !important;
  }

  .section4content {
    font-size: 20px;
    margin-left: -50px !important;
    margin-top: 20px;
    height: 320px;
  }
}

@media (max-width: 300px) {
  .section4content {
    font-size: 20px;
    margin-left: -40px !important;
  }
}

@media (max-width: 340px) {
  .section4 {
    padding-left: 70px;
    gap: 6px;
  }

  .section4Heading {
    font-size: 30px;
  }

  .section4content {
    font-size: 20px;
  }

  .section4Stats {
    flex-wrap: wrap;
  }

  .section3Adm11Logo {
    margin-left: 150px !important;
  }
}

@media (max-width: 290px) {
  .section3Adm11Logo {
    margin-left: 180px !important;
  }
}

/* 
.role{
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 30px;
    height: 60px;
    width: 180px;
}

.role1{
    margin-right: 20px;
    
} */
@media (max-width: 1340px) {
  .buttonHome button {
    width: 120px;
    background-color: transparent;
    color: white;
    border-radius: 20px;
    font-size: 18px;
    border: 1px solid grey;
    height: 44px;
    margin-top: 20px;
    font-weight: 400;
    margin-left: -60px;
  }
  .roleHome a {
    font-size: 1rem !important;
    line-height: 1.5rem;
    border-radius: 30px;
    height: 60px;
    width: 180px;
  }
}
@media (max-width: 770px) {
  .buttonHome button {
    width: 120px;
    background-color: transparent;
    color: white;
    border-radius: 20px;
    font-size: 1px;
    border: 1px solid grey;
    height: 33px;
    margin-top: 0px;
    font-weight: 400;
    margin-left: -60px;
  }
  .roleHome a {
    font-size: 0.9rem !important;
    line-height: 1.5rem;
    border-radius: 30px;
    height: 60px;
    width: 180px;
  }
}
.roleHome a {
  font-size: 1.4rem;
  line-height: 1.5rem;
  border-radius: 30px;
  height: 60px;
  width: 180px;
}
@media (max-width: 1380px) {
  .section1AdmRight {
    padding-left: 150px;
    width: 50vw;
  }
}

@media (max-width: 1120px) {
  .section1AdmRight {
    padding-left: 90px;
    width: 50vw;
  }
}

@media (max-width: 850px) {
  .section1AdmRight {
    padding-left: 100px;
    width: 50vw;
  }

  .section1AdmRight h1 {
    font-size: 3.3rem;
    margin-bottom: 0.8rem;
  }

  .section1AdmRight p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    letter-spacing: 2px;
    font-weight: 200;
  }

  .button {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .role {
    margin-top: 20px;
  }
}

@media (max-width: 560px) {
  .section1AdmRight {
    width: 50vw;
    text-align: justify;
  }

  .section1AdmRight h1 {
    font-size: 3rem;
    margin-bottom: 0.8rem;
  }

  .section1AdmRight p {
    font-size: 1.2rem;
    line-height: 2.5rem;
    letter-spacing: 2px;
    font-weight: 200;
  }
}

@media (max-width: 740px) {
  .section1AdmRight {
    padding-left: 60px;

    width: 50vw;
    text-align: justify;
  }
}

@media (max-width: 500px) {
  .section1AdmRight {
    padding-left: 30px;

    width: 50vw;
    text-align: justify;
  }
}

@media (max-width: 1680px) {
  .section1Adm {
    background-blend-mode: multiply;

    background-position-x: 80%;
  }
}

@media (max-width: 1300px) {
  .section1Adm {
    background-position-x: 70%;
  }
}

@media (max-width: 860px) {
  .section1Adm {
    background-position-x: 65%;
  }
}

@media (max-width: 690px) {
  .section1Adm {
    background-position-x: 60%;
  }
}

@media (max-width: 1330px) {
  .section2Adm2 {
    padding-right: 130px;
  }
}

@media (max-width: 1185px) {
  .section2Adm2 {
    padding-right: 10px;
  }
}

@media (max-width: 1380px) {
  .section2Adm1 {
    padding-left: 145px;
  }
}

@media (max-width: 1120px) {
  .section2Adm1 {
    padding-left: 80px;
  }
}

@media (max-width: 740px) {
  .section2Adm2 {
    text-align: justify;
  }
}

@media (max-width: 670px) {
  .section2Adm {
    display: flex;
    flex-direction: column;
  }

  .section2Adm2 {
    padding-left: 80px;
    padding-top: 30px;
    width: 600px;
  }

  .section2Adm1 {
    width: 600px;
  }
}

@media (max-width: 600px) {
  .section2Adm2 {
    padding-left: 80px;
    width: 550px;
  }

  .section2Adm1 {
    font-size: 18px;
    width: 550px;
  }
}

@media (max-width: 560px) {
  .section2Adm2 {
    padding-left: 60px;
    width: 500px;
  }

  .section2Adm1 {
    font-size: 18px;
    width: 500px;
    padding-left: 60px;
  }
}

@media (max-width: 560px) {
  .section2Adm2 {
    padding-left: 60px;
    width: 450px;
  }

  .section2Adm1 {
    font-size: 18px;
    width: 450px;
    padding-left: 60px;
  }
}

@media (max-width: 450px) {
  .section2Adm2 {
    padding-left: 30px;
    width: 450px;
  }

  .section2Adm1 {
    font-size: 18px;
    width: 450px;

    padding-left: 30px;
  }

  .section2Adm1 p {
    line-height: 0.5;
  }
}

@media (max-width: 440px) {
  .section2Adm2 {
    padding-left: 30px;
    width: 330px;
  }
  .section7HEading {
    font-size: 35px !important;
  }
  .section7Content {
    font-size: 18px;
  }
  .section2Adm1 {
    font-size: 16px;
    width: 390px;
    padding-left: 30px;
  }
  .section4content {
    font-size: 20px;
    margin-left: -30px;
  }
}

@media (max-width: 410px) {
  .section2Adm2 {
    padding-left: 30px;
    width: 350px;
  }
  .section63F {
    padding-left: 0px !important;
  }
  .section2Adm1 {
    font-size: 16px;
    width: 350px;
    padding-left: 30px;
  }

  .section4content {
    font-size: 20px;
    margin-left: -50px;
  }
}

@media (max-width: 370px) {
  .section2Adm2 {
    padding-left: 30px;
    width: 330px;
  }

  .section2Adm1 {
    font-size: 16px;
    width: 330px;
    padding-left: 30px;
  }
}

@media (max-width: 300px) {
  .section2Adm2 {
    padding-left: 30px;
    width: 290px;
  }

  .section2Adm1 {
    font-size: 16px;
    width: 290px;
    padding-left: 30px;
  }
}

@media (max-width: 290px) {
  .section4content {
    font-size: 20px;
    margin-left: -40px;
  }
}

@media (max-width: 1702px) {
  .section5ContentPContent {
    font-size: 33px;
    line-height: 1.5;
  }
}

@media (max-width: 1560px) {
  .section5ContentPHeadingMain {
    font-size: 24px;
  }

  .section5ContentPContent {
    font-size: 28px;
    line-height: 1.5;
  }
}

@media (max-width: 1400px) {
  .section5ContentPHeadingMain {
    font-size: 22px;
  }

  .section5ContentPContent {
    font-size: 25px;
    line-height: 1.5;
  }
}

@media (max-width: 1310px) {
  .section5ContentPHeadingMain {
    font-size: 20px;
  }

  .section5ContentPContent {
    font-size: 23px;
    line-height: 1.5;
  }
}

@media (max-width: 1310px) {
  .section5ContentLogo img {
    margin-bottom: 50px;
  }
}

@media (max-width: 1400px) {
  .section5 {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    padding-left: 200px;
    padding-right: 250px;
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (max-width: 1150px) {
  .section5 {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (max-width: 1007px) {
  .section5 {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (max-width: 890px) {
  .section5ContentLogo img {
    margin-bottom: 80px;
  }
}

@media (max-width: 827px) {
  .section5 {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    padding-left: 90px;
    padding-right: 60px;
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (max-width: 775px) {
  .section5 {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    padding-left: 90px;
    padding-right: 30px;
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (max-width: 690px) {
  .section5ContentLogo img {
    margin-bottom: 30px;
  }

  .section5ContentPContent {
    width: 400px;
  }
}

@media (max-width: 600px) {
  .section5 {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    padding-left: 50px;
    padding-right: 30px;
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (max-width: 490px) {
  .section5ContentPHeadingMain {
    width: 270px;
  }

  .section5ContentLogo img {
    margin-left: 30px;
  }

  .section5ContentPContent {
    width: 350px;
  }
}

@media (max-width: 360px) {
  .section5ContentLogo img {
    margin-left: 50px;
  }
}

@media (max-width: 380px) {
  .section5ContentPContent {
    width: 290px;
  }

  .section5HeadingH {
    margin-left: -20px;
  }
}

@media (max-width: 340px) {
  .section5ContentPContent {
    width: 260px;
  }

  .section5HeadingH {
    margin-left: -30px;
  }
}

@media (max-width: 320px) {
  .section5ContentPHeadingMain {
    width: 250px;
  }
}

@media (max-width: 290px) {
  .section5ContentPContent {
    width: 200px;
  }
}

@media (max-width: 420px) {
  .section5ContentPContent {
    width: 250px;
  }
}

@media (max-width: 1604px) {
  .section3Adm11Content {
    padding-top: 70px;
  }
}

@media (max-width: 1294px) {
  .section3Adm11ContentHeading,
  .section3Adm12ContentHeading,
  .section3Adm21ContentHeading,
  .section3Adm22ContentHeading,
  .section3Adm31ContentHeading,
  .section3Adm32ContentHeading {
    font-size: 26px;
  }

  .section3Adm12ContentP,
  .section3Adm11ContentP,
  .section3Adm12ContentP,
  .section3Adm21ContentP,
  .section3Adm22ContentP,
  .section32Adm11ContentP,
  .section3Adm32ContentP {
    font-size: 15px;
  }
}

@media (max-width: 1026px) {
  .section3Adm1,
  .section3Adm2,
  .section3Adm3 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .section3Adm11,
  .section3Adm12,
  .section3Adm21,
  .section3Adm22,
  .section3Adm31,
  .section3Adm32 {
    border-right: 0px dotted black;
    width: 800px;
    margin-left: 50px;
  }

  .section3Adm31 {
    border-bottom: 1px dotted black;
  }
}

@media (max-width: 1026px) {
  .section3Adm11,
  .section3Adm12,
  .section3Adm21,
  .section3Adm22,
  .section3Adm31,
  .section3Adm32 {
    width: 800px;
    margin-left: 20px;
  }
}

@media (max-width: 900px) {
  .section3Adm11,
  .section3Adm12,
  .section3Adm21,
  .section3Adm22,
  .section3Adm31,
  .section3Adm32 {
    width: 800px;
    margin-left: 0px;
  }
}

@media (max-width: 900px) {
  .section3Adm11,
  .section3Adm12,
  .section3Adm21,
  .section3Adm22,
  .section3Adm31,
  .section3Adm32 {
    width: 700px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 670px) {
  .section3Adm11,
  .section3Adm12,
  .section3Adm21,
  .section3Adm22,
  .section3Adm31,
  .section3Adm32 {
    width: 600px;
  }

  .section3Adm11ContentHeading,
  .section3Adm12ContentHeading,
  .section3Adm21ContentHeading,
  .section3Adm22ContentHeading,
  .section3Adm31ContentHeading,
  .section3Adm32ContentHeading {
    width: 240px;
  }

  .section3Adm12ContentP,
  .section3Adm11ContentP,
  .section3Adm12ContentP,
  .section3Adm21ContentP,
  .section3Adm22ContentP,
  .section32Adm11ContentP,
  .section3Adm32ContentP {
    width: 240px;
  }
}

@media (max-width: 600px) {
  .section3Adm11,
  .section3Adm12,
  .section3Adm21,
  .section3Adm22,
  .section3Adm31,
  .section3Adm32 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: -180px;
  }
}

@media (max-width: 3340px) {
  .section63Logo img {
    min-width: 50%;
    max-width: 50%;
    min-height: 100%;
    max-height: 100%;
    /* margin-bottom:  40px; */
    margin-top: 20px;
  }
}

@media (max-width: 2560px) {
  .section63Logo img {
    min-width: 55%;
    max-width: 55%;
    min-height: 100%;
    max-height: 100%;
    /* margin-bottom:  40px; */
    margin-top: 20px;
  }
}

@media (max-width: 2430px) {
  .section63Logo img {
    min-width: 58%;
    max-width: 58%;
    min-height: 100%;
    max-height: 100%;
    /* margin-bottom:  40px; */
    margin-top: 20px;
  }
}

@media (max-width: 2310px) {
  .section63Logo img {
    max-width: 60%;
  }
}

@media (max-width: 2260px) {
  .section63Logo img {
    max-width: 62%;
    padding-bottom: 20px;
  }
}

@media (max-width: 2200px) {
  .section63Logo img {
    max-width: 68%;
  }
}

@media (max-width: 2000px) {
  .section63Logo img {
    max-width: 72%;
  }
}

@media (max-width: 1935px) {
  .section63Logo img {
    max-width: 80%;
  }
}

@media (max-width: 1935px) {
  .section63Logo img {
    max-width: 85%;
    margin-bottom: 0px;
  }
}

@media (max-width: 1855px) {
  .section62H {
    width: 130%;
  }
}

@media (max-width: 1825px) {
  .section63Logo img {
    max-width: 90%;
    margin-bottom: 0px;
  }
}

@media (max-width: 1725px) {
  .section63Logo img {
    max-width: 95%;
    margin-bottom: 0px;
  }
}

@media (max-width: 1704px) {
  .section63Logo img {
    max-width: 105%;
    margin-top: 60px;
    margin-bottom: 0px;
  }

  .section63SubH,
  .section62H,
  .section63P,
  .section63F {
    padding-left: 40px;
    margin-top: -10px !important;
  }
}

@media (max-width: 1696px) {
  .section63Logo img {
    max-width: 105%;
    margin-top: 80px;
    margin-bottom: 0px;
  }
}

@media (max-width: 1648px) {
  .section63Logo img {
    max-width: 110%;
    margin-top: 90px;
    margin-bottom: 0px;
  }

  .section61 {
    gap: 20px;
  }
}

@media (max-width: 1590px) {
  .section63Logo img {
    max-width: 120%;
    margin-top: 90px;
    margin-bottom: 0px;
  }

  .section61 {
    gap: 20px;
  }
}

@media (max-width: 1550px) {
  .section6 {
    padding-left: 150px;
  }
}

@media (max-width: 1500px) {
  .section6 {
    padding-left: 100px;
  }
}

@media (max-width: 1350px) {
  .section6Div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .section61 {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
    padding: 0 50px;
    border-right: 0px dotted black;
  }

  .section61 {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
    padding: 0 50px;
    border-right: 0px dotted black;
  }
  .section63 {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
    padding: 0 50px;
  }

  .section63Logo img {
    width: 330px;
    height: 330px;
  }
}

@media (max-width: 895px) {
  .section6Div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .section61 {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
    margin-left: -200px;
    border-right: 0px dotted black;
  }

  .section62 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    padding: 0 50px;
    margin-left: -200px;
    border-right: 0px dotted black;
  }

  .section63 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 90%;
    padding: 0 50px;
    margin-left: -200px;
  }

  .section63Logo img {
    width: 330px;
    height: 330px;
  }
}

@media (max-width: 840px) {
  .section6HeadingC {
    margin-left: -50px;
  }
}

@media (max-width: 690px) {
  .section6 .section6Div {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    /* text-align: center; */
    gap: 10px;
  }

  .section61 {
    /* padding: 0 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
    /* margin-left: -100px; */
    text-align: justify;
    border-right: 0px dotted black;
  }

  .section62 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    text-align: justify;
    border-right: 0px dotted black;
  }

  .section63 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    text-align: justify;
  }
}

@media (max-width: 735px) {
  .section63Logo img {
    width: 300px;
    height: 320px;
  }
}

@media (max-width: 690px) {
  .section6Div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .section61 {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
    margin-left: -50px;
    border-right: 0px dotted black;
  }

  .section62 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    margin-left: -50px;
    border-right: 0px dotted black;
  }

  .section63 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    padding: 0 50px;
    margin-left: -50px;
  }

  .section63Logo img {
    width: 330px;
    height: 330px;
  }

  .section62H {
    padding-left: 20px;
  }

  .section6HeadingC {
    padding-left: 70px;
    width: 100%;
  }
  .section6 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    margin-left: -100px;
  }

  .section61Logo img {
    min-width: 90%;
    max-width: 80%;
    min-height: 90%;
    margin-left: 10px;
  }

  .section62Logo img {
    min-width: 90%;
    max-width: 80%;
    min-height: 90%;
    margin-left: 10px;
  }
}

@media (max-width: 900px) {
  .section63P {
    padding-left: 0px;
  }
}

.slider {
  width: 1300px;
  max-width: 100vw;
  height: 700px;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.slider .list {
  position: absolute;
  width: max-content;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  transition: 1s;
}
.slider .list img {
  width: 1300px;
  max-width: 100vw;
  height: 100%;
  object-fit: cover;
}
.slider .buttons {
  position: absolute;
  top: 45%;
  left: 5%;
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.slider .buttons button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff5;
  color: #fff;
  border: none;
  font-family: monospace;
  font-weight: bold;
}
.slider .dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.slider .dots li {
  list-style: none;
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin: 10px;
  border-radius: 20px;
  transition: 0.5s;
}
.slider .dots li.active {
  width: 30px;
}
@media screen and (max-width: 768px) {
  .slider {
    height: 400px;
  }
}

.item img {
  width: 20px;
}

.section5Content1 {
  display: flex;
  width: 80vw;
}

.section5Content2 {
  display: flex;
  border: 1px solid grey;
  width: 80vw;
}

.section5Content3 {
  display: flex;
  border: 1px solid grey;
  width: 80vw;
}
