/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.admission1sectionContent {
  padding: 100.2px 200px;

  font-size: 18px;
  font-weight: 100;
  letter-spacing: 1.5px;
  line-height: 2;
  color: #003283;
}

.admission1sectionButton button:active {
  top: 0px;
  box-shadow: 0px 0px 0px rgba(15, 165, 60, 0);
}
.admission1sectionButton {
  padding: 2px 200px;
  margin-bottom: 100px;
}

.admission1sectionButton button:active {
  top: -10px;
  box-shadow: 0px 10px 10px rgba(15, 165, 60, 0.2);

  -webkit-transform: rotateX(20deg);
  -moz-transform: rotateX(20deg);
  -ms-transform: rotateX(20deg);
  transform: rotateX(20deg);
}

.admission1sectionButton button {
  display: inline-block;
  position: relative;
  top: 0;

  background-color: #a4db3a;
  padding: 22px 66px 22px 54px;
  font-size: 18px;
  cursor: pointer;
  color: #003283;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 0px 0px rgba(15, 165, 60, 0.1);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.admission3section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.admission3sectionContent {
  margin: 238.2px 238.2px;
  color: white;
}
.admission3sectionContent2 {
  width: 400px;
  line-height: 2;
  font-size: 12px;
}
.admission3sectionheading {
  font-size: 28px;
  font-weight: 600;
}

.admission3sectionContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-weight: 100;
}

.admission2section {
  padding: 100px 200px;
}
.admission2sectionHeading {
  font-size: 28px;
  color: #003283;
  font-weight: 600;
  margin-bottom: 50px;
}
.admission2sectionContenet {
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.admission2sectionContenet1Heading {
  font-size: 18px;
  font-weight: 500;
}

.admission2sectionContenet1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admission2sectionContenet1Content {
  font-size: 13px;
  color: #003283;
  font-weight: 100;
  letter-spacing: 1.3px;
  opacity: 0.8;
}

.headingChanged li {
  list-style-type: disc;
  list-style: inside;
  padding: 5px 0px;
}

.admission4section {
  width: 100%;
  height: 100%;
}

.admission4sectionLeft {
  width: 30%;
  height: 100%;
  padding-top: 200px;
  padding-left: 200px;
}

.admission4sectionRight {
  width: 70%;
  height: 100%;
}

.admission4sectionLeftHeading {
  font-size: 26px;
  color: #003283;
  font-weight: 600;
}

.admission4sectionLeftContent {
  font-size: 13px;
  font-weight: 100;
  color: #003283;
  opacity: 0.8;
  padding-top: 20px;
}
.admission4section {
  display: flex;
}

.admission4sectionRight {
  display: flex;
  flex-direction: column;
}
form {
  display: flex;
  flex-direction: column;
}

.formfield1 {
  width: 100%;
  border: 0.5px solid grey;
  height: 50px;
  border-radius: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.formfield1::placeholder {
  padding-left: 100px;
}
.admission4sectionRight {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formAdmission {
  margin: 50px 0px;
  border-radius: 30px;
  color: #a3a3a3;
  font-size: 14px;
  position: relative;
  padding: 50px 50px;
  margin-right: 500px;
  left: 300px;
  background-color: #fff;
  box-shadow: -9px -1px 80px -30px #3e328c;
}

#message {
  width: 100%;
  border: 0.5px solid grey;
  height: 80px;
  border-radius: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.pppp {
  margin: 20px 0px;
  font-size: 12px;
}

form > button {
  margin-top: 20px;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.7s;
  background-size: 270% auto;
  font-size: 19px;
  color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    90deg,
    rgba(163, 0, 98, 1) 14%,
    rgba(168, 34, 131, 1) 31%,
    rgba(185, 41, 212, 1) 43%,
    rgba(0, 212, 255, 1) 100%
  );
}
form > button:hover {
  background-position: right center;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
}
@media screen and (max-width: 870px) {
  .admission1sectionContent {
    padding: 100.2px 100px;

    font-size: 14px;
    font-weight: 100;
    letter-spacing: 1.5px;
    line-height: 2;
    color: #003283;
  }
  .admission1sectionButton {
    padding: 2px 100px;
  }
  .admission1sectionButton button {
    font-size: 14px;
  }
  .admission2sectionContenet {
    padding-left: 50px !important;
  }
  .admission2section {
    padding: 100px 100px;
  }
}

@media screen and (max-width: 540px) {
  .admission1sectionContent {
    padding: 50.2px 50px;
  }
  .admission1sectionButton {
    padding: 2px 50px;
  }

  .admission2section {
    padding: 90px 50px;
  }
  .admission2sectionContenet{
    gap: 40px;
  }

  .admission3sectionContent2{
    width: 200px;
  }
}


@media screen and (max-width: 420px) {
  .admission1sectionContent {
    padding: 30.2px 30px;
  }
  .admission1sectionButton {
    padding: 2px 30px;
  }

  .admission2section {
    padding: 40px 30px;
  }

  .admission2sectionContenet{
    padding-left: 0px !important;
  }
}


@media screen and (max-width: 1510px) {
  .admission4sectionLeft{
    padding-top: 100px;
    padding-left: 100px;
  }
  .formAdmission{
    left: 200px;
    margin-right: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .admission4section{
    flex-direction: column;
  }

  .admission4sectionLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px !important;
    width: 100%;
    padding-left: 0px !important;
  }

   .formAdmission{
    left: 50px;
    margin-right: 100px;
    /* width: 100%; */
  } 

  .admission4sectionRight{
    width: 100%;
  }

  .admission4sectionLeftContent{
    padding: 20px 10px;
  }
}

