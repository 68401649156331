.footer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1a064e;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
  padding-left: 300px;
  padding-right: 300px;
  margin-bottom: 200px;
  color: white;
}

.containerHeading {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 30px;
  margin-top: 200px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.HeadingLogo {
  min-height: 100%;
  max-height: 100%;
}

.HeadingLogo img {
  width: 2.5vw;
}

.headingLogoText {
  font-size: 1.2rem;
  font-weight: 500;
}

.containerCards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.link1,
.link2 {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link3,
.link4 {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.link1Head,
.link2Head,
.link3Head {
  font-size: 15px;
  font-weight: 600;
}

.link2Li,
.link3Li,
.link1Li {
  display: flex;
  font-size: 13px;
  flex-direction: column;
  gap: 10px;
}

.link3Li {
  flex-wrap: wrap;
  height: 6.7vw;
}

/*  */

.containerFooter {
  width: 100%;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 100;
  background-color: #5c36f9;
  position: relative;
  color: white;
}

.link4Li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  width: 300px;
}

.link4Li1,
.link4Li2 {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  gap: 30px;
  line-height: 1.6;
}

@media (max-width: 1390px) {
  .containerFooter {
    height: 4vw;
  }
}
@media (max-width: 1000px) {
  .containerFooter {
    height: 6vw;
  }
}
/* @media (max-width:600px){
  .containerFooter{
    height:10vw;
  }
  
} */
@media (max-width: 400px) {
  .containerFooter {
    font-size: 8px;
  }
}

.link4Li1Logo {
  width: 5rem;
  height: 4rem;
  border-radius: 100%;
  background-color: #ffd954;
}

.link4Li2Logo {
  width: 4.5rem;
  height: 4rem;
  border-radius: 100%;
  background-color: #01ff85;
}

@media (max-width: 1620px) {
  .link3Li {
    height: 7.3vw;
  }
}

@media (max-width: 1479px) {
  .link3Li {
    height: 10.3vw;
  }
}
@media (max-width: 1050px) {
  .link3Li {
    height: 12.3vw;
  }
}

@media (max-width: 1530px) {
  .fsize {
    font-size: 11px;
  }
}

@media (max-width: 1430px) {
  .link1 {
    width: 15%;
  }
}

@media (max-width: 1390px) {
  .link1,
  .link2,
  .link3,
  .link4 {
    width: 50%;
    height: 25%;
    padding-bottom: 40px;
  }
}

@media (max-width: 1390px) {
  .link4Li {
    gap: 10px;
    margin-left: 0px;
  }
  .link4Li2Content,
  .link4Li1Content {
    font-size: 14px;
  }
  .footerContainer{
    margin-bottom: 50px;
  }
  .containerCards {
    margin-left: -100px;
  }
  .containerHeading {
    margin-left: -100px;
    margin-top: 100px;
  }
  
}

@media (max-width: 900px) {
  .link4Li {
    gap: 10px;
    margin-left:100px;

  }
  .link4Li2Content,
  .link4Li1Content {
    font-size: 14px;
  }

  .containerCards {
    margin-left: -180px;
  }
  .containerHeading {
    margin-left: -180px;
  }
}
@media (max-width: 550px) {
  .link4Li {
    gap: 10px;
    margin-left:200px;

  }
  .link4Li2Content,
  .link4Li1Content {
    font-size: 14px;
  }

  .containerCards {
    margin-left: -180px;
  }
  .containerHeading {
    margin-left: -180px;
  }
  .footerContainer{
    padding-left: 250px;
  }
}

@media (max-width: 450px) {
  .link4Li {
    gap: 10px;
    margin-left:200px;

  }
  /* .link4Li2Content,
  .link4Li1Content {
    font-size: 14px;
  }

  .containerCards {
    margin-left: -180px;
  }
  .containerHeading {
    margin-left: -180px;
  } */
}
/* @media (max-width: 1000px) {
  .containerCards {
    height: 60vw;
    width: 80vw;
  }
  .containerHeading {
    margin-top: 0px !important;
  }
} */
/* @media (max-width: 444px) {
  .containerCards {
    margin-left: -250px !important;
  }
  .containerHeading {
    margin-left: -250px !important;
  }
} */
@media (max-width: 360) {
  .link3Li {
    height: 250px !important;
  }
  .link4 {
    margin-top: 150px !important;
  }
}

@media (max-width: 800px) {
  .containerCards {
    height: 60vw;
    width: 50vw;
    gap: 50px;
    margin-left: -100px;
    position: relative;
    left: -70px;
  }
  .link1,
  .link2,
  .link3{
    margin-bottom: 30px;
  }

  .containerFooter {
    margin-top: 50px;
  }
  .link4Li2Content,
  .link4Li1Content {
    font-size: 10px;
  }
  .link3 {
    display: flex !important;
    align-items: start !important;
    justify-content: start !important;
  }
  /* .containerHeading {
    margin-left: -100px;
  } */
}
@media (max-width: 800px) {
  .link3Li {
    height: 16vw;
    text-align: left !important;
  }
}
@media (max-width: 610px) {
  .footerContainer{
    padding-right: 0px !important;
}
}

@media (max-width: 450px) {
  .footerContainer{
    padding-left: 250px !important;
}
}
@media (max-width: 400px) {
  .cardssssss{
    margin: 0px 40px;
  }
  .cardElementAssignment{
    padding-right: 0px !important;
  }
  .containerFooter{
    height: 14vw !important;
  }
}
@media (max-width: 700px) {
  .link1,
  .link2,
  .link3,
  .link4 {
    margin-bottom: 30px;
  }

  .containerFooter {
    margin-top: 100px;
    height: 10vw;
  }
}
@media (max-width: 450px) {
  .link1,
  .link2,
  .link3,
  .link4 {
    margin-bottom: 70px;
  }
.link4{
  position: relative;
  top: -60px;
}

}
@media (max-width: 450px) {
  /* .link1,
  .link2,
  .link3,
  .link4 {
    margin-bottom: 30px;
  } */

  .containerFooter {
    margin-top: 150px;
    height: 10vw;
  }
}

@media (max-width: 360px) {
  /* .link1, .link2 ,.link4 {
      margin-bottom: 100px;
    } */
    .link3Head{
      width: 150px;
    }
  .link3Li {
    width: 200px;
    flex-direction: column;
    height: 50px;
  }

  .footerContainer{
    padding-right: 0px !important;
  }
  /* .footerContainer{
      height: 800px;
    }

    .containerCards{
      margin-left: -200px;
    }
    .containerHeading{
      margin-left: -200px;
    }

    .containerCards{
      height: 200px;
    } */

  .link4Li1Logo,
  .link4Li2Logo {
    width: 3rem !important;
    height: 3rem !important;
  }
  /* .containerFooter{
        margin-top: 150px;
        font-feature-settings: 6px;
      } */
}
.link4Li2Logo img {
  object-fit: cover;
  min-width: 100%;
  border-radius: 50%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.link4Li1Logo img {
  object-fit: cover;
  min-width: 100%;
  border-radius: 50%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.link2Li img {
  z-index: 300000000;
  width: 20px;
  height: 20px;
}
@media (max-width: 390px) {
  .link3,
  .link2 {
    margin-left: -24px !important;
  }
  .link4Li{
    margin-left: 50px;
    width: 200px;
  }
}
