* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  justify-content: space-evenly;
}

.section65 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 30px;
  font-family: sans-serif;
  letter-spacing: 1px;
  padding-left: 300px;
  width: 50%;
  line-height: 1.5;
  position: relative;
  top: -40px;
  left: -200px;
}
@media (max-width: 1060px) {
  .section65 {
    top: 0px !important;
    left: 0px !important;
  }
}
.section65 h1 {
  font-weight: 400;
}

.section65 h6 {
  font-weight: 200;
}

.section2 {
  width: 50%;
}

@media (max-width: 1450px) {
  .section65 h1 {
    margin-left: -180px;
  }

  .section65 h6 {
    margin-left: -180px;
  }

  .section65 {
    padding-left: 260px;
  }
}

@media (max-width: 944px) {
}

@media (max-width: 860px) {
  .section65 h1 {
    font-size: 50px;
  }

  .section65 h6 {
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  .section65 {
    margin-top: 80px;
  }
}

@media (max-width: 860px) {
  .section65 h1 {
    font-size: 40px;
  }

  .section65 h6 {
    font-size: 15px;
  }
}

@media (max-width: 633px) {
  .section65 h1 {
    font-size: 30px;
  }

  .section65 h6 {
    font-size: 10px;
  }
}

@media (max-wdth: 600px) {
  .section65 {
    margin-top: 120px;
    padding-left: 260px;
  }
  .section65 h1 {
    margin-left: -230px;
  }

  .section65 h6 {
    padding-right: -230px;
  }

  .section65 h1 {
    margin-left: -220px;
  }

  .section65 h6 {
    margin-left: -220px;
  }

  .section65 {
    padding-left: 219px;
  }
}

@media (max-width: 500px) {
  .section65 {
    margin-top: 140px;
    padding-left: 50px;
  }
  .section65 h6 {
    font-size: 3vw;
  }

  .section65 h1 {
    margin-left: 0px;
  }

  .section65 h6 {
    margin-left: 0px;
  }
}

@media (max-width: 460px) {
  .section65 h1 {
    font-size: 25px;
  }

  .section65 h6 {
    font-size: 8px;
  }
}

@media (max-width: 370px) {
  .section65 h1 {
    font-size: 19px;
  }

  .section65 h6 {
    font-size: 6px;
  }
}

@media (max-width: 450px) {
  .section65 {
    margin-top: 100px;
  }
}

@media (max-width: 390px) {
  .section65 {
    margin-top: 110px;
  }

  .section65 h1 {
    margin-left: -10px;
  }

  .section65 h6 {
    margin-left: -10px;
  }
}
