.wrappersx {
  display: flex;
}

.paddingless {
  margin-top: -450px;
  margin-bottom: 150px;
}

.woaw {
  margin-top: -200px;
}

body {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.our-services2 {
  position: relative;
  top: 0px;
}
.title9 {
  position: relative;
  top: 200px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23px;
}

.expart-team1 {
  /* font-size: 20px; */
  font-weight: 600;
  letter-spacing: 0.2rem;
  color: #6440fa;
}

.about-us56 {
  font-size: 30px;
  font-weight: 500;
}

.innerServices1 {
  position: relative;
  top: 300px;
  display: flex;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.innerServices2 {
  position: relative;
  top: 350px;
  display: flex;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.service-13 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  margin-left: 300px;
  padding-right: 100px;
}

.service-23 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
}

.service-33 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
  margin-right: 200px;
}

.service-41 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  margin-left: 300px;
  padding-right: 100px;
}

.service-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
}

.service-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
  margin-right: 200px;
}

.duis-aliquet-vulputate6 {
  color: #a3a3a3;
  line-height: 30px;
  letter-spacing: 2;
}

.duis-aliquet-vulputate8 {
  color: #a3a3a3;
  line-height: 30px;
  letter-spacing: 2;
}

.duis-aliquet-vulputate10 {
  color: #a3a3a3;
  line-height: 30px;
  letter-spacing: 2;
}

.section3 {
  display: flex;
  position: relative;
}
.bg img {
  position: relative;
  display: flex;
  top: 400px;
  width: 1710px;
}

.innerMeet {
  display: flex;
  position: relative;
  /* width: 100%; */
}

.innerMeet img {
  display: flex;
  position: relative;
  top: -430px;
  left: 100px;
  width: 1610px;
  background-repeat: no-repeat;
  background-size: cover;
}

.meet-the-main-container1 {
  position: absolute;
  left: 400px;
  top: -300px;

  /* font-size: 340px; */
}

.about-us5 {
  text-align: center;
  color: white;
  font-size: 90px;
}

.button3 {
  position: absolute;
  width: 244px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  background-color: #6440fa;
  border-radius: 10px;
  color: white;
  top: -50px;
  left: 750px;
}

.title8 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  position: relative;
  top: -400px;
}

.expart-team1 {
  font-size: 15px;
}

.our-team-member1 {
  font-size: 30px;
  padding-top: 5px;
  color: white;
}

.team1 {
  display: flex;
  width: 100%;
  position: relative;
  top: -370px;
  /* overflow: hidden; */
  align-items: center;
  justify-content: center;
  padding-left: 130px;
  flex-direction: row;
  padding-left: -100px;
}

.div40 {
  width: 25%;
  line-height: 2;
}

.div42 {
  width: 25%;
  line-height: 2;
}

.div44 {
  width: 25%;
  line-height: 2;
}

.div46 {
  width: 25%;
  line-height: 2;
}

.div40 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}

.div42 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}

.div44 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}
.div46 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}

.educoda-menager4 {
  color: #a3a3a3;
  padding-left: 10px;
}

.elijah-charlotte1 {
  padding-left: 10px;
}

.blue {
  background-color: #6440fa;
  border-radius: 10px;
}

.wrappers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -200px;
}

.about-us6 {
  width: 50%;
  /* border: 2px solid black; */
}

.about-us-history1 {
  color: #6440fa;
  letter-spacing: 2px;
  /* padding: 30px; */
}

.education-is-about1 {
  /* padding: 0 30px; */
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 2px;
}

.phasellus-quis-interdum1 {
  color: #a3a3a3;
  /* width: 600px; */
  letter-spacing: 2px;
  font-size: 13px;
  line-height: 2;
  /* padding: 10px 33px; */
}
.text16 {
  width: 50%;
  /* border: 2px solid black; */
}

.about-us6 {
  display: flex;
  /* border: 2px solid black; */
}

.nunc-egestas-sodales-container1 {
  width: 500px;
  letter-spacing: 2px;
  font-size: 12px;
  line-height: 2;
  padding: 10px 33px;
}

.companies img {
  margin-top: -400px;
  width: 60%;
}

.companies {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mask-group-icon39 {
  /* height: 99.38px; */
}

.img-inner {
  display: flex;
  background-color: #a3a3a3;
  border-radius: 20px;
}

.img-2innerrrr {
  margin-bottom: 20px;
  display: flex;
  position: relative;
  height: 330px;
  width: 330px;
  /* background-color: #a3a3a3; */
  /* border-radius: 20px; */
}

.img-child1 {
  display: flex;
  border: 5px solid #6440fa;
  border-radius: 20px;
}

.img-inner img {
  overflow: hidden;
  border-radius: 20px;
}

/* *************************************************************************** */

.aboutUsSection1Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 200px;
}
.aboutUsSection1Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 200px;
}
.boxGrey {
  width: 30vw;
  height: 30vw;
  position: relative;
  left: 30px;
  border-radius: 20px;
  background-color: #a3a3a3;
}
.section1Content {
  display: flex;
  width: 80%;
}

.section1Left {
  width: 50%;
}

.section1Right {
  padding: 50px;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.section1RightHeading {
  font-size: 15px;
  letter-spacing: 4px;
  color: #6440fa;
}

.section1RightHeading2 {
  font-size: 28px;
}

.section1RightContent {
  color: #777;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1.5;
  letter-spacing: 1.1px;
}

.section1RightContentBottom {
  line-height: 3;
  font-size: 14px;
}

.section1RightContentSignDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Circleimg {
  width: 20%;
  height: 100%;
}

.circlegg {
  width: 70px;
  border-radius: 50%;
  height: 70px;
  background-color: #a3a3a3;
}

.signimg {
  width: 70%;
}

.signimg img {
  width: 130px;
}

.ourServiceCardsWrapperrr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
}
.marginChange {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-top: 200px;
  margin-bottom: 500px;
  height: 30vw;
}

.peopleCardssss {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.testimonialok {
  margin-top: 200px;
}
