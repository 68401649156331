* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainHome {
  /* background-image: url("httpsdf://cwz-harshitasparrowtech-gmailcom.vercel.app/bg2@2x.png"); */
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  justify-content: space-between;
}

.section64 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-family: sans-serif;
  letter-spacing: 1px;
  padding-left: 200px;
  width: 50%;
  line-height: 1.5;
}

.section64 h1 {
  font-weight: 400;
  font-size: 5vw;
}

.section64 h6 {
  font-weight: 200;
  font-size: 1.4vw;
}

.section2 img {
  display: flex;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
}

@media (max-width: 1340px) {
  .section64 h1 {
    margin-left: -50px;
  }

  .section64 h6 {
    margin-left: -50px;
  }
}

@media (max-width: 1060px) {
  .section64 {
    padding-left: 100px;
  }
}

@media (max-width: 800px) {
  .section64 {
    margin-top: 80px;
  }
}

@media (max-wdth: 600px) {
  .section64 {
    margin-top: 120px;
    padding-left: 60px;
  }
}

@media (max-width: 500px) {
  .section64 {
    margin-top: 140px;
    padding-left: 50px;
  }
  .section64 h6 {
    font-size: 3vw;
  }

  .section64 h1 {
    margin-left: 0px;
  }

  .section64 h6 {
    margin-left: 0px;
  }
}

@media (max-width: 450px) {
  .section64 {
    margin-top: 100px;
  }
}

@media (max-width: 390px) {
  .section64 {
    margin-top: 110px;
  }

  .section64 h1 {
    margin-left: -10px;
  }

  .section64 h6 {
    margin-left: -10px;
  }
}
