*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a{
    color: unset;
    text-decoration: none;
}

@media screen and (max-width: 700px){
    
}

@media screen and (max-width: 550px){
    
}

@media screen and (max-width: 550px){
    
}