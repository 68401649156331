body {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.ourServiceWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-top: 300px;
  margin-bottom: 700px;
  height: 30vw;
}

.ourServiceTitleLower {
  width: 50%;
  padding: 20px;
  line-height: 1.5;
  font-size: 30px;
  font-weight: 500;
}

.ourServiceTitle {
  display: flex;
  align-items: center;
  justify-self: center;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.ourServiceTitleUppper {
  font-weight: 400;
  letter-spacing: 0.2rem;
  color: #6440fa;
  font-size: 14px;
}

.ourServiceCardsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourServiceCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
}

.cardsCommonClass {
  width: 33.33%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.logoCommonClass {
  height: 30%;
}

.ourServiceCardsHeadingCommon {
  height: 10%;
  font-size: 15px;
}

.logoCommonClass img {
  /* height: 70px; */
  width: 70px;
}

.ourServiceCardsContentCommon {
  width: 100%;
  height: 60%;
  flex-wrap: wrap;
  color: #777777;
  font-size: 14px;
  line-height: 2;
}

.title9 {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 23px; */
}

.expart-team1 {
  /* font-size: 20px; */
  font-weight: 600;
  letter-spacing: 0.2rem;
  color: #6440fa;
}

.about-us56 {
  font-size: 30px;
  font-weight: 500;
}

.innerServices1 {
  position: relative;
  top: 300px;
  display: flex;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.innerServices2 {
  position: relative;
  top: 350px;
  display: flex;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.service-13 {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 10px;
  width: 33.33%;
  margin-left: 300px;
  padding-right: 100px;
}

.service-23 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
}

.service-33 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
  margin-right: 200px;
}

.service-41 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  margin-left: 300px;
  padding-right: 100px;
}

.service-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
}

.service-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 33.33%;
  padding-right: 100px;
  margin-right: 200px;
}

.duis-aliquet-vulputate6 {
  color: #a3a3a3;
  line-height: 30px;
  letter-spacing: 2;
}

.duis-aliquet-vulputate8 {
  color: #a3a3a3;
  line-height: 30px;
  letter-spacing: 2;
}

.duis-aliquet-vulputate10 {
  color: #a3a3a3;
  line-height: 30px;
  letter-spacing: 2;
}

.section3 {
  display: flex;
  position: relative;
}
.bg img {
  position: relative;
  display: flex;
  top: 400px;
  width: 1710px;
}

.innerMeet {
  display: flex;
  position: relative;
  /* width: 100%; */
}

.innerMeet img {
  display: flex;
  position: relative;
  top: -430px;
  left: 100px;
  width: 1610px;
  background-repeat: no-repeat;
  background-size: cover;
}

.meet-the-main-container1 {
  position: absolute;
  left: 400px;
  top: -300px;

  /* font-size: 340px; */
}

.about-us5 {
  text-align: center;
  color: white;
  font-size: 90px;
}

.button3 {
  position: absolute;
  width: 244px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  background-color: #6440fa;
  border-radius: 10px;
  color: white;
  top: -50px;
  left: 750px;
}

.title8 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  position: relative;
  top: -400px;
}

.expart-team1 {
  font-size: 15px;
}

.our-team-member1 {
  font-size: 30px;
  padding-top: 5px;
  color: white;
}

.team1 {
  display: flex;
  width: 100%;
  position: relative;
  top: -370px;
  /* overflow: hidden; */
  align-items: center;
  justify-content: center;
  padding-left: 130px;
  flex-direction: row;
  padding-left: -100px;
}

.div40 {
  width: 25%;
  line-height: 2;
}

.div42 {
  width: 25%;
  line-height: 2;
}

.div44 {
  width: 25%;
  line-height: 2;
}

.div46 {
  width: 25%;
  line-height: 2;
}

.div40 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}

.div42 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}

.div44 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}
.div46 img {
  width: 270px;
  height: 230px;
  border-radius: 10px;
}

.educoda-menager4 {
  color: #a3a3a3;
  padding-left: 10px;
}

.elijah-charlotte1 {
  padding-left: 10px;
}

.blue {
  background-color: #6440fa;
  border-radius: 10px;
}

.wrappers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -200px;
}

.about-us6 {
  width: 50%;
  /* border: 2px solid black; */
}

.about-us-history1 {
  color: #6440fa;
  letter-spacing: 2px;
  padding: 30px;
}

.education-is-about1 {
  padding: 0 30px;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 2px;
}

.phasellus-quis-interdum1 {
  color: #a3a3a3;
  width: 600px;
  letter-spacing: 2px;
  font-size: 13px;
  line-height: 2;
  padding: 10px 33px;
}
.text16 {
  width: 50%;
  /* border: 2px solid black; */
  padding-left: 100px;
}

.about-us6 {
  display: flex;
  /* border: 2px solid black; */
}

.nunc-egestas-sodales-container1 {
  width: 500px;
  letter-spacing: 2px;
  font-size: 12px;
  line-height: 2;
  padding: 10px 33px;
}

.companies img {
  margin-top: -400px;
  width: 60%;
}

.companies {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mask-group-icon39 {
  height: 99.38px;
}

.img-inner {
  display: flex;
  position: relative;
  height: 590px;
  width: 520px;
  background-color: #a3a3a3;
  border-radius: 20px;
  margin-left: 300px;
}

.img-2innerrrr {
  margin-bottom: 20px;
  display: flex;
  position: relative;
  height: 330px;
  width: 330px;
  /* background-color: #a3a3a3; */
  /* border-radius: 20px; */
}

.img-child1 {
  z-index: -10;
  display: flex;
  border: 5px solid #6440fa;
  height: 470px;
  width: 520px;
  border-radius: 20px;
  position: relative;
  top: -400px;
  left: 350px;
}

.img-inner img {
  overflow: hidden;
  border-radius: 20px;
}

.section1Admllll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 800px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  margin-top: -400px;
}

.peopleCardsWrapperHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.peopleCardsWrapperHeadingUpper {
  color: #6440fa;
  letter-spacing: 3px;
}

.peopleCardsWrapperHeadingLower {
  font-size: 40px;
  color: white;
}

.peopleCards1img {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.peopleCards1img img {
  width: 290px;
  border-radius: 20px;
  height: 230px;
}

.peopleCards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

@media (max-width: 1334px) {
  .peopleCards1img img {
    width: 230px;
    border-radius: 20px;
    height: 210px;
  }
  .peopleCards1Content {
    padding: 15px 20px !important;
  }
}

@media (max-width: 1080px) {
  .peopleCards1img img {
    width: 190px;
    border-radius: 20px;
    height: 160px;
  }
  .peopleCards1Content {
    padding: 15px 25px !important;
  }

  .peopleCards1ContentHead,
  .peopleCards1ContentBottom {
    font-size: 12px;
  }
}

@media (max-width: 1000px) {
  .peopleCards1img img {
    width: 150px;
    border-radius: 20px;
    height: 120px;
  }
  .peopleCards1Content {
    padding: 10px 15px !important;
  }
  .peopleCards1ContentHead,
  .peopleCards1ContentBottom {
    font-size: 10px;
  }
}

@media (max-width: 530px) {
  .peopleCards1img img {
    width: 100px;
    border-radius: 20px;
    height: 90px;
  }
  .peopleCards1Content {
    padding: 5px 15px !important;
    gap: 5px !important;
  }
  .peopleCards1ContentHead,
  .peopleCards1ContentBottom {
    font-size: 8px;
  }
  .section1Admllll {
    height: 600px !important;
  }
}

@media (max-width: 1334px) {
  .bgimtext {
    right: 200px !important;
    font-size: 60px !important;
  }
}

@media (max-width: 1000px) {
  .bgimtext {
    right: 200px !important;
    font-size: 40px !important;
  }
}

@media (max-width: 719px) {
  .bgimImg img {
    margin-top: 200px;
  }
  .bgimtext {
    top: 250px;
  }
}

.peopleCards1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.peopleCards1Content {
  padding: 15px 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(225, 225, 225, 0.936);
}

.peopleCards1Contentgreen {
  background-color: #6440fa !important;
  color: white;
}

/* .section1AdmRight111{
    z-index: -0;
    height: 100%;
    width: 100%;
    position: relative;
}

.section1AdmRight111 img{
    min-width: 80%;
    max-width: 80%;
    max-height: 60%;
    min-height: 60%;
} */

.bgimImg {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: end;
}

.bgimImg img {
  min-width: 90%;
  max-width: 90%;
  max-height: 60%;
  min-height: 60%;
}
.bgimtext {
  position: absolute;
  width: 60%;
  right: 300px;
  text-align: center;
  color: white;
  font-size: 80px;
}

.section1Right {
  padding: 50px;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.section1Contentt {
  display: flex;
  width: 90%;
}

.boxGreyy {
  border-radius: 20px;
  width: 30vw;
  height: 30vw;
  position: relative;
  left: 30px;
  top: 70px;
  border-radius: 20px;
  background-color: #a3a3a3;
}

.boxGreyy img {
  border-radius: 20px;
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.circlegg img {
  object-fit: cover;
  min-width: 100%;
  border-radius: 50%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.signimg {
  width: 70%;
  display: flex;
}

.signimg img {
  width: 130px;
}

.Circleimg {
  width: 20%;
  height: 100%;
}

@media (max-width: 1423px) {
  .section1Right {
    width: 50%;
  }
}

@media (max-width: 972px) {
  .section1Contentt {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .section1Right {
    width: 100%;
    text-align: center;
  }

  .boxGreyy {
    width: 30vw;
    height: 30vw;
    position: relative;
    left: 15%;
    top: 20px;
    border-radius: 20px;
    background-color: #a3a3a3;
  }

  .Circleimg {
    width: 50%;
    display: flex;
    height: 100%;
    align-items: ce;
    justify-content: right;
  }
  .aboutUsSection1Wrapper {
    margin-top: 80px !important;
  }
}

@media (max-width: 608px) {
  .section1RightHeading2,
  .section1RightContent,
  .section1RightContentBottom {
    text-align: left;
  }
}

@media (max-width: 1115px) {
  .ourServiceCards {
    text-align: left;
  }
}

@media (max-width: 1216px) {
  .ourServiceCards {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .ourServiceWrapper {
    margin-top: 800px;
    margin-bottom: 1100px;
  }

  .ourServiceTitleLower {
    width: 100%;
    font-size: 22px;
  }

  .cardsCommonClass {
    width: 100% !important;
  }
}

@media (max-width: 813px) {
  .bgimtext {
    right: 150px !important;
    font-size: 30px !important;
  }

  .peopleCardsWrapperHeadingLower {
    font-size: 20px !important;
  }
  .peopleCardsWrapperHeadingUpper {
    font-size: 10px !important;
  }
}

@media (max-width: 719px) {
  .bgimtext {
    right: 150px !important;
    top: 270px;
    font-size: 30px !important;
  }

  .peopleCardsWrapperHeadingLower {
    font-size: 20px !important;
  }
  .peopleCardsWrapperHeadingUpper {
    font-size: 10px !important;
  }
}
@media (max-width: 630px) {
  .bgimtext {
    right: 100px !important;
    top: 250px;
    font-size: 30px !important;
  }

  .peopleCardsWrapperHeadingLower {
    font-size: 20px !important;
  }
  .peopleCardsWrapperHeadingUpper {
    font-size: 10px !important;
  }
}
@media (max-width: 500px) {
  .bgimtext {
    right: 100px !important;
    top: 230px;
    font-size: 25px !important;
  }

  .peopleCardsWrapperHeadingLower {
    font-size: 20px !important;
  }
  .peopleCardsWrapperHeadingUpper {
    font-size: 10px !important;
  }
}
@media (max-width: 416px) {
  .bgimtext {
    right: 70px !important;
    top: 210px;
    font-size: 25px !important;
  }

  .peopleCardsWrapperHeadingLower {
    font-size: 20px !important;
  }
  .peopleCardsWrapperHeadingUpper {
    font-size: 10px !important;
  }
}

@media (max-width: 370px) {
  .bgimtext {
    right: 70px !important;
    top: 230px;
    font-size: 20px !important;
  }

  .peopleCardsWrapperHeadingLower {
    font-size: 17px !important;
  }
  .peopleCardsWrapperHeadingUpper {
    font-size: 8px !important;
  }
}
/* @media (max-width:339px) {
    .section1Admllll{
        height: 1100px !important;
    }
} */

@media (max-width: 712px) {
  .ourServiceWrapper {
    margin-top: 900px;
    margin-bottom: 1150px;
  }
}

@media (max-width: 543px) {
  .ourServiceWrapper {
    margin-top: 1000px;
    margin-bottom: 1300px;
  }
}

@media (max-width: 428px) {
  .ourServiceWrapper {
    margin-top: 1100px;
    margin-bottom: 1400px;
  }
}

@media (max-width: 333px) {
  .bgimtext {
    font-size: 16px !important;
  }
}

@media (max-width: 334px) {
  .ourServiceWrapper {
    margin-top: 1200px;
    margin-bottom: 1500px;
  }
}

@media (max-width: 288px) {
  .ourServiceWrapper {
    margin-top: 1250px;
    margin-bottom: 1550px;
  }
}

@media (max-width: 274px) {
  .ourServiceWrapper {
    margin-top: 1300px;
    margin-bottom: 1600px;
  }
}

.peopleCards1Content:hover {
  background-color: #6440fa;
  transition: 500ms linear;
  color: white;
}
