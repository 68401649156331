.navbar {
  z-index: 99999;
  font-family: sans-serif;
  color: white;
  position: absolute;
  display: flex;
  height: 100px;
  justify-content: space-between;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background: transparent;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.1);
}

.mainLogo {
  display: flex;
  width: 16.55vw;
  padding-top: 5px;
  padding-left: 50px;
}

.mainLogo img {
  width: 130px;
  height: 90px;
}

.contact {
  display: flex;
  flex-direction: column;
  object-fit: cover;
  align-items: center;
  width: 30%;
  font-size: 13px;
  padding-bottom: 45px;
  text-align: center;
  line-height: 2;
  padding-right: 150px;
}

.mailLogo {
  width: 220px;
  color: #494949;
  background-color: #01ff85;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.links {
  font-size: 14px;
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  padding-right: 80px;
  padding-left: 100px;
  padding-top: 50px;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding-bottom: 40px;
}

.link img {
  width: 18px;
  height: 18px;
}

.burger {
  display: none;
}

@media (max-width: 1520px) {
  .contact {
    margin-left: 100px;
  }
}

@media (max-width: 1390px) {
  .contact {
    margin-left: 100px;
  }
  .link {
    display: none;
  }
  .burger {
    display: flex;
    flex-direction: column;
    margin-top: -12px !important;
  }
}

.needHelp p {
  width: 300px;
}

@media (max-width: 1340px) {
  .contact {
    margin-left: 250px;
  }
}

@media (max-width: 1100px) {
  .contact {
    padding-left: 50px;
  }
}

@media (max-width: 1060px) {
  .contact {
    display: none;
  }
}

@media (max-width: 800px) {
  .link {
    display: none;
  }
  .burger {
    display: flex;
    flex-direction: column;
  }
}

.linkimg img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

@media (max-width: 600px) {
  /* .mainLogo img {
    width: 270px;
    height: 50px;
    border-radius: 10px;
    margin-top: 13px;
    margin-left: -40px;
    box-shadow: 2px 2px 2px 2px #4327b4;
  } */
}

@media (max-width: 450px) {
  .links {
    margin-bottom: -30px;
  }
}

.wpIcon {
  width: 30px;
}

/* navbar new */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;1,500;1,600&family=Poppins:wght@400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  font-family: "Roboto", sans-serif;
}
body {
  padding-top: 40px;
}

@media screen and(max-width: 768px) {
  body {
    padding-top: 0 px;
  }
}

/* -----------------------------------------------------------NavBar--------------------------------------------------- */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.header {
  border-bottom: 1px solid #e2e8f0;
}

.navbar {
  display: flex;
  position: fixed;
  top: 0;
  /* width: 100%; */
  background-color: #6440fa;
  transition: all 0.5s ease-in-out;
  z-index: 999999999999999999;
}

.navbar {
  z-index: 9999;
  width: 100vw;
}
.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar ul li {
  display: inline-block;
  color: red;
  padding: 3px 8px;
}
.navbar ul li a {
  text-decoration: none;
  color: #222;
  display: inline-block;
  padding: 10px;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  /* width: 25px; */
  height: 3px;
  /* margin: 5px auto; */
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  /* position: absolute; */
  /* right: 100px;
  top: 100px; */
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.nav-item {
  margin-left: 5rem;
}

.nav-link {
  font-size: 2rem;
  font-weight: 400;
  color: #475569;
  font-family: "Righteous", cursive;
}

.nav-link:hover {
  background-color: hsl(217, 85%, 56%);
  border-radius: 2px;
  transition: 0.5s;
}

.nav-logo {
  font-size: 2.1rem;
  /* font-weight: 500; */
  color: black;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 1390px) {
  .nav-menu {
    position: fixed;
    /* left: -100%; */
    top: 5rem;
    flex-direction: column;
    background-color: #fff;
    /* width: 100%; */
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .links {
    position: relative;
    top: -10px;
    right: -25px;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    margin-right: 25px;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
}
