* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainService {
  width: 100%;
  height: 100%;
}
@media (max-width: 1680px) {
  .sectionContact {
    background-blend-mode: multiply;

    background-position-x: 80%;
  }
}

@media (max-width: 1300px) {
  .sectionContact {
    background-position-x: 70%;
  }
}

@media (max-width: 860px) {
  .sectionContact {
    background-position-x: 65%;
  }
}

@media (max-width: 690px) {
  .sectionContact {
    background-position-x: 60%;
  }
}
.green {
  color: #01ff85;
}

.section {
  /* background-image: url("https://cwz-harshitasparrowtech-gmailcom.vercel.app/bg2@2x.png"); */
  display: flex;
  height: 65vw;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
}

.section68 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-family: sans-serif;
  letter-spacing: 1px;
  padding-left: 200px;
  width: 50%;
  line-height: 1.5;
  position: relative;
  top: 0px;
}
@media (max-width: 1060px) {
  .section68 {
    top: 0px !important;
  }
}
.section68 h1 {
  font-weight: 400;
  font-size: 5vw;
}

.section68 h6 {
  font-weight: 200;
  font-size: 1.4vw;
}
@media (max-width: 1340px) {
  .section69 h1 {
    margin-left: -50px;
  }

  .section69 h6 {
    margin-left: -50px;
  }
}

@media (max-width: 1060px) {
  .section68 {
    padding-left: 100px;
  }
}

@media (max-width: 800px) {
  .section68 {
    margin-top: 80px;
  }
}

@media (max-width: 600px) {
  .section68 {
    margin-top: 120px;
    padding-left: 60px;
  }
}

@media (max-width: 500px) {
  .section68 {
    margin-top: 140px;
    padding-left: 50px;
  }
  .section68 h6 {
    font-size: 3vw;
  }
}

@media (max-width: 450px) {
  .section68 {
    margin-top: 100px;
  }
}

@media (max-width: 390px) {
  .section68 {
    margin-top: 110px;
  }
}
