.mapImage img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 100px;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.mapImage {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 500px; */
  margin-bottom: 200px;
}

@media (max-width: 2487px) {
  .mapImage {
    /* margin-top: 300px; */
    margin-bottom: 150px;
  }
  .form {
    margin-left: -100px;
  }
}

@media (max-width: 1930px) {
  .form {
    margin-left: -100px;
  }
}

@media (max-width: 1767px) {
  .mapImage {
    /* margin-top: 200px; */
    margin-bottom: 100px;
  }
}

@media (max-width: 1390px) {
  .form {
    flex-direction: column;
  }

  .formSection2 {
    width: 50% !important;
    margin-bottom: 50px;
  }
}

@media (max-width: 1140px) {
  .formSection2 {
    width: 60% !important;
  }
}

/* @media (max-width: 980px) {
  .formSection2 {
    width: 70% !important;
  }

  .formSection1 {
    margin-left: 50px !important;
  }
} */

@media (max-width: 868px) {
  .formSection2 {
    margin-left: 50px;
  }
}

@media (max-width: 1519px) {
  .mapImage {
    /* margin-top: 200px; */
    margin-bottom: 50px;
  }
}

@media (max-width: 1011px) {
  .mapImage {
    /* margin-top: 200px; */
    margin-bottom: 0px;
  }

  .formSection1 {
    margin-left: 100px;
  }
}

@media (max-width: 600px) {
  .formSection2 {
    margin-left: 80px !important;
  }
}

@media (max-width: 615px) {
  .section21Logo,
  .section22Logo,
  .section23Logo {
    margin-right: -70px;
    margin-top: -30px;
  }

  .section21,
  .section22,
  .section23 {
    flex-direction: column;
  }
  .section21Logo img {
    position: relative;
    top: 20px;
    right: 40px;
    margin-top: -10px !important;
  }

  .section22Logo img {
    position: relative;
    top: 20px;
    right: 40px;
    margin-top: -10px !important;
  }

  .section23Logo img {
    position: relative;
    top: 20px;
    right: 40px;
    margin-top: -10px !important;
  }
  .section21Content {
    padding-top: -90px;
    position: relative;
    bottom: 70px;
    margin-left: 0px !important;
  }

  .section23Content {
    padding-top: -90px;
    position: relative;
    bottom: 70px;
    margin-left: 0px !important;
  }
  .section22Content {
    padding-top: -90px;
    position: relative;
    bottom: 70px;
    margin-left: 0px !important;
  }
}

.section212 {
  display: flex;
  flex-direction: column;
}

.section21Content,
.section22Content,
.section23Content {
  margin-top: -10px !important;
  margin-left: -50px;
}

@media (max-width: 540px) {
  .formSection11 {
    flex-direction: column;
  }

  .first {
    margin-right: 0px !important;
  }
}

.first {
  margin-right: 40px;
}

@media (max-width: 530px) {
  .formSection2 {
    margin-left: 100px !important;
  }
  .section2Heading {
    font-size: 20px !important;
    text-align: center;
  }
}

@media (max-width: 295px) {
  .formSection2 {
    margin-left: 50px !important;
  }
}

.formSection2 {
  width: 40%;
}
.formSection2 {
  background-color: rgba(100, 64, 250, 0.07);
  padding: 30px;
  border-radius: 20px;
  width: 30%;
}

.section2Heading {
  font-size: 30px;
}

.section21ContentHeading {
  color: #a3a3a3;
  font-size: 15px;
  padding-top: 30px;
}

.section21ContentText {
  font-size: 18px;
  line-height: 2;
}

.section22ContentHeading {
  color: #a3a3a3;
  font-size: 15px;
  padding-top: 30px;
}

.section22ContentText {
  font-size: 18px;
  line-height: 2;
}
.section23ContentHeading {
  color: #a3a3a3;
  font-size: 15px;
  padding-top: 30px;
}

.section23ContentText {
  font-size: 18px;
  line-height: 2;
}

.section21 {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 100px;
}

.section21Logo img {
  width: 70px;
  background-color: #6440fa;
  border-radius: 50px;
  padding: 13px;
  margin-top: 10px;
}

.section22 {
  display: flex;
  align-items: center;
  gap: 100px;
}

.section22Logo img {
  width: 70px;

  background-color: #6440fa;
  border-radius: 50px;
  padding: 13px;
  margin-top: 25px;
}

.section23 {
  display: flex;
  align-items: center;
  gap: 100px;
}

.section23Logo img {
  width: 70px;
  background-color: #6440fa;
  border-radius: 50px;
  padding: 13px;
  margin-top: 10px;
}

#name {
  width: 300px;
}

.formSection44 {
  display: flex;
  flex-direction: column;
}

.formSection1 {
  padding: 100px;
  padding-left: 200px;
}
@media (max-width: 500px) {
  .formSection1 {
    padding: 100px;
    padding-left: 170px !important;
  }
}
@media (max-width: 350px) {
  .formSection1 {
    padding: 100px;
    padding-left: 150px !important;
  }
}

.formSection11 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 900px) {
  .mapImage {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 100px; */
    margin-bottom: 0px;
  }
}
@media (max-width: 600px) {
  .mapImage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    margin-bottom: -50px;
  }
}

@media (max-width: 400px) {
  .mapImage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    margin-bottom: -50px;
  }
}

.firstHolder {
  display: flex;
  gap: 20px;
  border: none;
  background-color: #f6f6f6;
  height: 60px;
  width: 100%;
  margin-bottom: 40px;
  border-radius: 10px;
}

.firstHolder::placeholder {
  padding: 10px;
  font-size: 12px;
  color: #a3a3a3;
}
.additional::placeholder {
  padding: 10px;
  font-size: 12px;
  color: #a3a3a3;
}

.additional {
  border: none;
  background-color: #f6f6f6;
  border-radius: 10px;
  height: 100px;
  width: 100%;
  margin-bottom: 40px;
}

.formSection44 {
  color: #a3a3a3;
  font-size: 15px;
}

.bluebtn {
  height: 70px;
  width: 220px;
  border: none;
  background-color: #6440fa;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 30px;
}

.section21 {
  margin-top: 50px;
}

.section22 {
  margin-top: 50px;
}

.section23 {
  margin-top: 50px;
}
