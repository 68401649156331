* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainHomie {
  /* background-image: url("https://cwz-harshitasparrowtech-gmailcom.vercel.app/bg2@2x.png"); */
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-repeat: no-repeat;
  background-size: 110% 30%;
  background-position: center top;
}
.inputContainer {
  display: flex;
  align-items: center;
}
#countryCode {
  width: 45%;
}
.inputFields {
  border: 1px solid rgba(26, 6, 68, 0.07);
  background-color: #fff;
  display: block;
  background-position: calc(100% - 20px) center; /* Adjust the value to position the arrow */
  -webkit-appearance: none;

  margin-bottom: 20px;
  padding: 15px 20px;
  width: 10px;
  outline: none;
  color: #7f7f7f;
  border-radius: 10px;
  text-align: start;

  margin-right: 5px; /* Adjust margin as needed */
}

.sectionContent {
  margin-top: 70px;
  background-position-x: 90%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
/* <div>
<form ref={assignmentForm}>
  {Your form elements here}
  <div onClick={openModel}>
    <input
      type="submit"
      className="startBtn"
      value="Let's Start +"
    />
  </div>
</form>
<PopupComponent
  isOpen={isModelOpen}
  closePopup={closeModel}
  message={popupMessage}
/>
</div> */
.section69 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-family: sans-serif;
  letter-spacing: 1px;
  padding-left: 100px;
  width: 50%;
  line-height: 1.5;
  position: relative;
  top: -120px;
}
@media (max-width: 1060px) {
  .section69 {
    top: 0px !important;
  }
}

.section69 h1 {
  font-weight: 400;
  font-size: 3.4vw;
}

.section69 h6 {
  font-weight: 200;
  font-size: 1.4vw;
}

.section2 {
  color: white;
  position: relative;
}

.section2 img {
  position: relative;
  display: flex;
  max-width: 100%;
  z-index: -1000000;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
}

@media (max-width: 1340px) {
  .section69 h1 {
    margin-left: -50px;
  }

  .section69 h6 {
    margin-left: -50px;
  }
}

@media (max-width: 1060px) {
  .section69 {
    padding-left: 100px;
  }
}

@media (max-width: 1390px) {
  .section69 {
    margin-top: 180px;
    padding-bottom: 100px;
  }
}

@media (max-wdth: 600px) {
  .section69 {
    margin-top: 120px;
    padding-left: 60px;
  }

  .elem1Heading {
    width: 460px;
  }
}
@media (max-width: 310px) {
  .buttonHome button {
    position: relative;
    left: -20px;
  }
}
@media (max-width: 500px) {
  .section69 {
    margin-top: 100px;
    padding-left: 100px;
    margin-bottom: -50px !important;
  }
  .buttonHome button {
    margin-left: -50px !important;
  }
  .section69 h1 {
    margin-left: 0px;
  }

  .section69 h6 {
    margin-left: 0px;
  }
}

@media (max-width: 450px) {
  .section69 {
    margin-top: 100px;
  }
}

@media (max-width: 390px) {
  .section69 {
    margin-top: 110px;
  }

  .section69 h1 {
    margin-left: -10px;
  }

  .section69 h6 {
    margin-left: -10px;
  }
}

/* .formWrapper{
    position: absolute;
    top: 200px;
    right: 190px;
} */

.formBox {
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  margin-right: 190px;
}
.formBox2 {
  background-color: #ebebeb;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  margin-right: 190px;
}

.formSection111 {
  border-bottom: 1px solid #6440fa;
}
/* 
.spanDiv {
    margin-top: 30px;
}




.spanDiv span {
    padding: 5px;
    font-size: 14px;
} */

.formSection22 {
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.form-input {
  width: 100%;
  height: 50px;
  border: 1px solid rgba(26, 6, 78, 0.07);
  background-color: #f3f7fa;
  border-radius: 10px;
  margin-bottom: 20px;
}
#vin::placeholder {
  padding: 20px;
}

.formLastField {
  width: 400px;
  height: 100px;
  border: 1px solid rgba(26, 6, 78, 0.07);
  background-color: #f3f7fa;
  border-radius: 10px;
}

/* .form-input::placeholder {
  padding-left: 20px;
} */
/* .formLastField::placeholder {
  padding-left: 20px;
}

.form-inputt::placeholder {
  padding-left: 20px;
}
.formLastFieldd::placeholder {
  padding-left: 20px;
} */

.formSection3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.formSection3 button {
  width: 90%;
  background-color: #01ff85;
  border: none;
  height: 50px;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

/* .spanDiv button{
    padding: 8px;
    margin: 8px;
    border: none;
    background-color: transparent;
    border-radius: 5px;
} */

/* .spanDiv button:hover{
    background-color: #6440fa;
    transition: 500ms linear; 
    color: white;
} */

.aHWrapperbuttons button:hover {
  background-color: #6440fa;
  transition: 500ms linear;
  color: white;
}

@media (max-width: 1530px) {
  .hero {
    position: relative;
    /* right: 200px !important; */
    top: 200px;
  }
}

@media (max-width: 1430px) {
  .hero {
    position: relative;
    /* right: 150px !important; */
    top: 200px;
  }
}

.hero2 {
  display: none;
}

@media (max-width: 1340px) {
  .hero {
    position: relative;
    right: 50px !important;
    top: 150px !important;
  }
  .hero2 {
    display: block !important;
    position: relative;
    right: 0;
    top: 50px;
  }
  .title99 {
    margin-top: 150px;
  }
}

@media (max-width: 1340px) {
  .hero {
    display: none;
  }
}

@media (max-width: 390px) {
  .section69 h6 {
    color: black !important;
  }
}

@media (max-width: 335px) {
  .hime span {
    color: black !important;
  }
}
